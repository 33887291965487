import * as API from '_axios'
import { ADMIN_URLS } from 'constants/url'


export const getAllMessages = async({queryKey})=>{
    const [, pageNo, filters]=queryKey
    
    const result=await API.post(
         `${ADMIN_URLS.getMessages}?pageNo=${pageNo || 0}`,
         {
            mess_from: filters.mess_from || null,
            mess_to  : filters.mess_to || null,
            title: filters.title || null,
            content: filters.content || null,

         }
    )
    return result
}

//get message by id

 export const getMessagesById = async ({queryKey}) =>{
    const [, id] = queryKey
    const result = await API.get(`${ADMIN_URLS.getMessagesById}/${id}`)
    return result
 }



