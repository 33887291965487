import * as API from '_axios'
import URLS from 'constants/url'

export const getAllUsers = async ({queryKey}) => {
    const [, pageNo, filters] = queryKey

    // const query = new URLSearchParams(filters).toString()
    const result = await API.post(`${URLS.ADMIN_URLS.getUsers}`, {
        id: filters.id,
        email: filters.email || null,
        fullname: filters.fullname || null,
        telephone: filters.telephone || null,
        pageno: pageNo,
    })
    return result
}

export const getUsersByFilter = async ({queryKey}) => {
    const [, filters] = queryKey
    const query = new URLSearchParams(filters).toString()
    const result = await API.get(`${URLS.ADMIN_URLS.getUsersByFilter}?${query}`)
    return result
}
export const getUserById = async ({queryKey}) => {
    const [, filters] = queryKey
    const result = await API.get(`${URLS.ADMIN_URLS.getUserById}/${filters}`)
    return result
}
export const setusertrustshield = async ({queryKey}) => {
    const user_id = queryKey.user_id
    const result = await API.put(
        `${URLS.ADMIN_URLS.changeUserTrustshiled}/${user_id}`,
    )
    return result
}

export const sendVerificationEmail = async ({queryKey}) => {
    const user_id = queryKey.user_id
    const result = await API.post(URLS.ADMIN_URLS.postUserVerificationEmail, {
        user_id,
    })
    return result
}

export const deactiveUser = async ({queryKey}) => {
    const data = {
        user_id: queryKey.user_id,
        deactivate_user: queryKey.deactivate_user,
        deactivate_notes:queryKey.deactivate_note
        // deactivate_values: queryKey.deactivate_values,
    }
    console.log(data)
    const token = queryKey.token
    const result = await API.post(URLS.ADMIN_URLS.postUserDeactivate, data, {
        headers: {
            Authorization: token,
        },
    })
    return result
}
export const setUserGoferType = async ({queryKey}) => {
    const data = {
        user_id: queryKey.user_id,
        gopher_type_id: queryKey.gopher_type_id,
    }
    const result = await API.post(URLS.ADMIN_URLS.postUserGoferTypeUpdate, data)
    return result
}
