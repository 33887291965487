import React from 'react'
import moment from 'moment'
import {Col, Row, List, Tag, Typography} from 'antd'
import {Spin} from 'antd'
import {useMutation, useQuery} from 'react-query'

import Item from './Item'
import * as otpQueries from '../../../queries/otp.queries'
import * as Styled from './counterview.styled'
import {Link} from 'react-router-dom'

const {Title} = Typography

function OtpView({params}) {
    const otpQuery = useQuery(['id', params.id], otpQueries.getOtpById)

    const otp = otpQuery.data?.data.data
    if (otpQuery.isLoading) {
        return (
            <Styled.Root>
                <Spin />
            </Styled.Root>
        )
    }

    return (
        <>
            <div className="order">
                <Col className="order_col">
                    <List
                        size="small"
                        bordered
                        style={{background: 'rgba(0,0,0,0.03)'}}
                        header={
                            <Title level={5} style={{height: '2.5rem'}}>
                                Basic Info
                            </Title>
                        }>
                        <Item
                            body={
                                <Link to={`/otp/${otp.id}`}>{otp.id}</Link> ||
                                'N/A'
                            }
                            title="Id "
                        />
                        <Item
                            body={
                                otp.user_id ? (
                                    <Link to={`/user/${otp.user_id}`}>
                                        {otp.user_id}
                                    </Link>
                                ) : (
                                    'N/A'
                                )
                            }
                            title="User Id "
                        />
                        <Item
                            body={otp.telephone || 'N/A'}
                            title="Telephone "
                        />
                        <Item body={otp.code || 'N/A'} title="OTP" />
                    </List>
                </Col>
                <Col className="order_col">
                    <List
                        size="small"
                        bordered
                        style={{background: 'rgba(0,0,0,0.03)'}}>
                        <Item body={otp.smshash || 'N/A'} title="Sms Hash" />
                        <Item
                            body={
                                <Tag
                                    color={
                                        otp
                                            ? otp.used === true
                                                ? 'cyan'
                                                : 'green'
                                            : null
                                    }>
                                    {otp
                                        ? otp.used === true
                                            ? 'USED'
                                            : 'UNUSED'
                                        : null}
                                </Tag>
                            }
                            title="Used"
                        />

                        <Item
                            body={
                                moment(otp.created_at).format(
                                    'YYYY-DD-MM (hh:mm A)',
                                ) || 'N/A'
                            }
                            title="Created On"
                        />
                        <Item
                            body={
                                moment(otp.updated_at).format(
                                    'YYYY-DD-MM (hh:mm A)',
                                ) || 'N/A'
                            }
                            title="Updated On"
                        />
                        <Item
                            body={
                                moment(otp.expired_at).format(
                                    'YYYY-DD-MM (hh:mm A)',
                                ) || 'N/A'
                            }
                            title="Expired On"
                        />
                    </List>
                </Col>
            </div>
        </>
    )
}

export default OtpView
