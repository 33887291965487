import {Checkbox, List, Modal, Switch, message} from 'antd'
import axios from 'axios'
import {useRef} from 'react'
import {useEffect, useState} from 'react'

function UserSettings({visible, toggleModal, defaultChecked, setOoaBraodcast}) {
    return (
        <Modal
            title="Settings"
            visible={visible}
            onOk={toggleModal}
            onCancel={toggleModal}
            onTitleClick={toggleModal}>
            <List>
                <List.Item>
                    <List.Item.Meta
                        title="OOA Subscribe"
                        // description={item.email}
                    />
                    <Switch
                        checked={defaultChecked}
                        onChange={() => {
                            setOoaBraodcast(!defaultChecked)
                        }}
                    />
                </List.Item>
            </List>
        </Modal>
    )
}

export default UserSettings
