import React from 'react'
import moment from 'moment'
import {Col, Row, List, Tag, Typography} from 'antd'
import {Spin} from 'antd'
import {useQuery} from 'react-query'

import Item from './Item'
import * as gopherOfferQueries from '../../../queries/gopheroffer.queries'
import * as Styled from './counterview.styled'
import {Link} from 'react-router-dom'

const {Title} = Typography

function GopherOfferView({params}) {
    const gopherOfferQuery = useQuery(
        ['id', params.id],
        gopherOfferQueries.getGopherOfferById,
    )

    const gopher_offer = gopherOfferQuery.data?.data.data

    // console.log(gopherOfferQuery.data?.data.data)
    if (gopherOfferQuery.isLoading) {
        return (
            <Styled.Root>
                <Spin />
            </Styled.Root>
        )
    }

    return (
        <>
            <div className="order">
                <Col className="order_col">
                    <List
                        size="small"
                        bordered
                        style={{
                            background: 'rgba(0,0,0,0.03)',
                            paddingTop: '1.05rem',
                        }}
                        header={<Title level={5}>Basic Info</Title>}>
                        <Item body={gopher_offer.id} title="ID#" />
                        <Item
                            body={
                                <Link to={`/booking/${gopher_offer.order_id}`}>
                                    {gopher_offer.order_id}
                                </Link>
                            }
                            title="Order ID "
                        />
                        <Item body={gopher_offer.title} title="Title" />
                        <Item
                            body={
                                <Link to={`/user/${gopher_offer.gopher_id}`}>
                                    {gopher_offer.gopher}
                                </Link>
                            }
                            title="Gopher Name"
                        />
                        <Item
                            body={
                                <Link to={`/user/${gopher_offer.requestor_id}`}>
                                    {gopher_offer.requestor}
                                </Link>
                            }
                            title="Requestor Name"
                        />
                        <Item
                            body={
                                gopher_offer.status === 'Declined' ? (
                                    <Tag color="red">{gopher_offer.status}</Tag>
                                ) : gopher_offer.status === 'Accepted' ? (
                                    <Tag color="green">
                                        {gopher_offer.status}
                                    </Tag>
                                ) : (
                                    <Tag color="blue">
                                        {gopher_offer.status}
                                    </Tag>
                                )
                            }
                            title="Status"
                        />
                        <Item
                            body={moment(gopher_offer.createdon).format(
                                'YYYY-DD-MM',
                            )}
                            title="Created On"
                        />
                    </List>
                </Col>
            </div>
        </>
    )
}

export default GopherOfferView
