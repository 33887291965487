import React, {useState, useEffect} from 'react'
import {Typography, Button, Space, Input, Row} from 'antd'
import {Link} from 'react-router-dom'
import {useQuery} from 'react-query'
import moment from 'moment'
import * as Queries from 'queries/address.queries'
import {QUERIES} from 'constants/index'
import DataTable from 'components/DataTable'

import * as Styled from './address-styled'
import fileDownload from 'js-file-download'
import axios from 'axios'
import {ReloadOutlined} from '@ant-design/icons'

const {Title} = Typography

function Address() {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: val => <Link to={`/address/${val}`}>{val}</Link>,
            sorter: (a, b) => a.id - b.id,
            width: 110,
        },
        {
            title: 'Addresable ID',
            key: 'addressable_id',
            render: val =>
                val.addressable_type === 'User' ||
                val.addressable_type === 'Business' ? (
                    <Link to={`/user/${val.addressable_id}`}>
                        {val.addressable_id}
                    </Link>
                ) : (
                    <Link to={`/booking/${val.addressable_id}`}>
                        {val.addressable_id}
                    </Link>
                ),
            sorter: (a, b) => a.id - b.id,
            width: 110,
        },
        {
            title: 'Addressable Type',
            key: 'addressable_type',
            render: val =>
                val.addressable_type === 'User' ||
                val.addressable_type === 'Business' ? (
                    <Link to={`/user/${val.addressable_id}`}>
                        {val.addressable_type}
                    </Link>
                ) : (
                    <Link to={`/booking/${val.addressable_id}`}>
                        {val.addressable_type}
                    </Link>
                ),
        },

        {
            title: 'Line 1',
            dataIndex: 'line1',
            key: 'line1',
            render: val => val || 'N/A',
        },
        {
            title: 'Line 2',
            dataIndex: 'line2',
            key: 'line2',
            render: val => val || 'N/A',
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            render: val => val || 'N/A',
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            render: val => val || 'N/A',
        },
        {
            title: 'Zip Code',
            dataIndex: 'zip_code',
            key: 'zip_code',
            render: val => val || 'N/A',
        },
        {
            title: 'Longitude',
            dataIndex: 'longitude',
            key: 'longitude',
            render: val => val || 'N/A',
        },
        {
            title: 'Latitude',
            dataIndex: 'latitude',
            key: 'latitude',
            render: val => val || 'N/A',
        },
        {
            title: 'Created On ',
            dataIndex: 'created_at',
            key: 'created_at',
            render: val => moment(val).format('YYYY-DD-MM (hh:mm A)') || 'N/A',
        },
    ]
    // function to export csv
    const [csv, setCsv] = useState(false)
    const [filters, setFilters] = useState({
        line1: '',
    })

    //a function which will be used to reload the api

    const filterQuery = useQuery(
        [QUERIES.GET_ALL_ADDRESS, 0, filters],
        Queries.getAllAddresses,
        {
            staleTime: 180000,
        },
    )

    function downloadCsv(e) {
        e.preventDefault()
        setCsv(true)
        const token = localStorage.getItem('token')
        const csvDate = moment(new Date()).format('DD_MM_YYYY')

        axios
            .get(`${process.env.REACT_APP_BASE_URL}/admin/addresses/csv`, {
                headers: {
                    Authorization: token,
                },
            })
            .then(res => {
                fileDownload(res.data, `Addresses_${csvDate}.csv`)
                setCsv(false)
            })
            .catch(err => {
                console.log(err)
                setCsv(false)
            })
    }

    function reloadApi() {
        filterQuery.refetch({
            queryKey: [QUERIES.GET_ALL_ADDRESS, 0, filters],
        })
    }

    return (
        <>
            <div
                style={{
                    textAlign: 'right',
                    marginRight: '30px',
                    marginBottom: '5px',
                }}>
                <Button
                    icon={<ReloadOutlined />}
                    style={{textAlign: 'right'}}
                    onClick={reloadApi}
                    type="primary">
                    Reload
                </Button>
            </div>
            <div className="addess__page">
                <DataTable
                    forceData={false}
                    pagination={true}
                    columns={columns}
                    filters={filters}
                    queryFunction={Queries.getAllAddresses}
                    queryKey={QUERIES.GET_ALL_ADDRESS}
                />

                <Button
                    style={{margin: '2rem', minHeight: '45px', display: 'flex'}}
                    onClick={e => downloadCsv(e)}
                    loading={csv}
                    type="light">
                    <img
                        style={{
                            width: '35px',
                            height: '35px',
                            marginRight: '5px',
                        }}
                        src="/csb.png"
                        alt=""
                    />
                    Download CSV
                </Button>
            </div>
        </>
    )
}

export default Address
