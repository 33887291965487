import React, {useState, useEffect} from 'react'
import {
    Form,
    Input,
    Button,
    textarea,
    DatePicker,
    message,
    Row,
    Col,
    Table,
} from 'antd'
import moment from 'moment'
import {useNavigate} from 'react-router'
import axios from 'axios'

function EditMessage({params}) {
    const [editUser, setEditUser] = useState({
        body: '',
        subject: '',
        expired_on: '',
        roleId: '',
        userRole: '',
    })

    const [sendMessageForm] = Form.useForm()
    const [formValues, setFormValues] = useState({})
    const [users, setUsers] = useState([])
    const [updateUser, setUpdateUser] = useState({})

    // const [editUser, setEditUser] = useState({})
    const navigate = useNavigate()
    // const myRef = useRef()
    const [currentPage, setCurrentPage] = useState(1) // State to track the current page number
    const [pageSize, setPageSize] = useState(10) // State to track the number of items per page
    const dateFormat = 'YYYY-MM-DD'
    const [expiredOn, setExpiredOn] = useState(null)

    const token = localStorage.getItem('token')
    const fetchData = async () => {
        try {
            const config = {
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json',
                },
            }
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/admin/inbox_message/send_message/${params.id}`,
                config,
            )
            console.log(response.data.data)
            setEditUser(response.data.data.inbox_message)
            setUpdateUser(response.data.data.inbox_message)
            setUsers(response.data.data.message_sent_to)
            setExpiredOn(response.data.data.inbox_message.expired_on)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    console.log('user ====>', editUser)

    const defaultDate = moment(editUser.expired_on)

    const initialValues = {
        subject: editUser.subject,
        body: editUser.body,
        expired_on: defaultDate,
    }

    const onFinish = async values => {
        console.log('values ===>', values)
        const formattedDate = values.expired_on
            ? moment(values.expired_on).format('YYYY-MM-DD HH:mm:ss Z')
            : null
        const formData = {...values, date: formattedDate}
        console.log('Form values:', formData)
        message.success('Sending message successfully!')
        try {
            // Replace the URL with your API endpoint
            const response = await fetch('https://example.com/api/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })

            if (response.ok) {
                console.log('Form data submitted successfully')
            } else {
                console.error('Failed to submit form data')
            }
        } catch (error) {
            console.error('Error submitting form data:', error)
        }
    }

    const onValuesChange = (key, value) => {
        setEditUser({...editUser, [key]: value})
    }

    return (
        <div
            className=""
            style={{
                width: '100%',
                height: '100%',
                margin: 'auto',
                marginTop: 30,
                // border: '1px solid gray',
                padding: 8,
                borderRadius: 10,
            }}>
            <Row style={{margin: 10}}>
                <Col
                    span={10}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                    <Form
                        initialValues={initialValues}
                        onValuesChange={onValuesChange}
                        onFinish={onFinish}
                        style={{
                            width: '70%',
                            background: 'rgba(0,0,0,0.03)',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            padding: '20px',
                            height: '380px',
                        }}>
                        <Form.Item
                            label="Subject"
                            name="subject"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your subject',
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Body"
                            name="body"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your body',
                                },
                            ]}>
                            <Input.TextArea rows={4} />
                        </Form.Item>
                        <Form.Item
                            label="Expired On"
                            name="expired_on"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your expired_on',
                                },
                            ]}>
                            <DatePicker
                                style={{width: '100%'}}
                                showTime
                                showToday
                                use12Hours
                                disabledDate={date =>
                                    moment(date).isBefore(moment())
                                }
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={12}>
                </Col>
            </Row>
        </div>
    )
}

export default EditMessage
