import React, {useState, useEffect} from 'react'
import {Typography, Button, Space, Input, Row} from 'antd'
import {Link} from 'react-router-dom'
import {useQuery} from 'react-query'
import moment from 'moment'
import * as Queries from 'queries/flags.queries'
import {QUERIES} from 'constants/index'
import DataTable from 'components/DataTable'

import * as Styled from './flags-styled'
import fileDownload from 'js-file-download'
import axios from 'axios'
import { ReloadOutlined } from '@ant-design/icons'

const {Title} = Typography

function Flags() {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: val => <Link to={`/flags/${val}`}>{val}</Link>,
            sorter: (a, b) => a.id - b.id,
            width: 110,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: val => val || 'N/A',
        },
        {
            title: 'Order ',
            key: 'order_id',
            render: val => <Link to={`/booking/${val.order_id}`}>{val.id}</Link>,
        },
        {
            title: 'Order ',
            key: 'order_id',
            render: val => <Link to={`/booking/${val.order_id}`}>{val.title}</Link>,
        },
        {
            title: 'User ',
            key: 'user_id',
            render: val => <Link to={`/user/${val.user_id}`}>{val.user_name}</Link>,
        },
        {
            title: 'Created On ',
            dataIndex: 'created_at',
            key: 'created_at',
            render: val => moment(val).format('YYYY-DD-MM (hh:mm A)') || 'N/A',
        },
       
    ]
    // function to export csv
    const [csv, setCsv] = useState(false)
    const [filters,setFilters]= useState({
        description : '',

    })

    //function which will be used to reload the api

    const filterQuery =useQuery(
        [QUERIES.GET_ALL_FLAGS, 0 ,filters],
        Queries.getAllFlags,
        {
           staleTime:180000,
        },
    )

    function downloadCsv(e) {
        e.preventDefault()
        setCsv(true)
        const token = localStorage.getItem('token')
        const csvDate = moment(new Date()).format('DD_MM_YYYY')

        axios
            .get(`${process.env.REACT_APP_BASE_URL}/admin/flags/csv`, {
                headers: {
                    Authorization: token,
                },
            })
            .then(res => {
                fileDownload(res.data, `Flags_${csvDate}.csv`)
                setCsv(false)
            })
            .catch(err => {
                console.log(err)
                setCsv(false)
            })
    }

    function reloadApi(){
        filterQuery.refetch({
            queryKey:[QUERIES.GET_ALL_FLAGS,0,filters]
        })
    }

    return (
        <>
        <div style={{textAlign: 'right',marginRight: '30px', marginBottom: '5px'}}>
            <Button icon={<ReloadOutlined/>} style={{textAlign:'right'}} onClick={reloadApi} type="primary">Reload</Button>

        </div>
        <div>
            <DataTable
                forceData={false}
                pagination={true}
                columns={columns}
                filters={filters}
                queryFunction={Queries.getAllFlags}
                queryKey={QUERIES.GET_ALL_FLAGS}
            />

            <Button
                style={{margin: '2rem', minHeight: '45px', display: 'flex'}}
                onClick={e => downloadCsv(e)}
                loading={csv}
                type="light">
                <img
                    style={{width: '35px', height: '35px', marginRight: '5px'}}
                    src="/csb.png"
                    alt=""
                />
                Download CSV
            </Button>
        </div>
        </>
    )  
}

export default Flags
