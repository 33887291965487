export const ADMIN_URLS = {
    getBookings: '/admin/orders',
    getBookingByFilter: '/admin/orders/by_filter',
    getBookingDetails: '/admin/orders/get_details',
    confirmPayout: '/admin/confirm_payout',

    postUserLogin: '/admin/auth/login',
    postUserCreate: '/admin/auth/create_user',

    getUsers: '/admin/user',
    getUserById: '/admin/user',
    getUsersByFilter: '/admin/user/by_filter',
    postUserVerificationEmail: '/admin/user/send_confirmation_email',
    postUserDeactivate: '/admin/user/deactivate_user',
    postUserGoferTypeUpdate: '/admin/user/setgophertype',
    changeUserTrustshiled:'/admin/user/change_trustshiled',
    
    getCoupons: '/admin/coupons',
    postCouponCreate: '/admin/coupons',
    getCouponsById: '/admin/coupons',
    updateCouponsById: '/admin/coupons',
    deleteCouponById: '/admin/coupons',

    getCounter: '/admin/counter_offer',
    getCounterById: '/admin/counter_offer',

    getReports: '/admin/report',
    getReportsById: '/admin/report',

    getRatings: '/admin/rating',
    getRatingsById: '/admin/rating',

    getOtp: '/admin/otp',
    getOtpById: '/admin/otp',

    getAddress: '/admin/addresses',

    getFlags: '/admin/flags',

    getReferral: 'admin/referral',

    getBids: '/admin/bids',
    getBidsById: '/admin/bids',

    getGopherOffer: '/admin/gopher_offer',
    getGopherOfferById: '/admin/gopher_offer',

    getMessages: 'admin/messages',
    getMessagesById: 'admin/messages',

    //Filter message
    getFilterMessage: '/admin/filter_message',

    getCostAdjustment : 'admin/cost_adjustment',
    getCostAdjustmentById : 'admin/cost_adjustment',

    getOrderDeclines : 'admin/order_declines',  
    getOrderDeclinesById : 'admin/order_declines',


    //Connected user
    getConnectedUser :'admin/connected_users',
    getConnectedUserById :'admin/connected_users',

    getOrderDeclines: 'admin/order_declines',
    getOrderDeclinesById: 'admin/order_declines',

    //webhooks
    getWebhooks:'admin/webhook',
    deleteWebHookById:'admin/webhook',
    updateWebHookById:'admin/webhook',
    addWebHook:'admin/webhook',

    //broadcast
    getUnsubscribedUsers:'admin/broadcast/OOA'
}

const defaults = {ADMIN_URLS}
export default defaults
