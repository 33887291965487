import React from 'react'
import moment from 'moment'
import {Col, Row, List, Tag, Typography} from 'antd'
import {Spin} from 'antd'
import {useMutation, useQuery} from 'react-query'

import Item from './Item'
import * as reportQueries from '../../../queries/report.queries'
import * as Styled from './counterview.styled'
import {Link} from 'react-router-dom'

const {Title} = Typography

function ReportView({params}) {
    const reportQuery = useQuery(['id', params.id], reportQueries.getReportById)

    const report = reportQuery.data?.data.data
    if (reportQuery.isLoading) {
        return (
            <Styled.Root>
                <Spin />
            </Styled.Root>
        )
    }

    return (
        <>
            <div className="order">
                <Col style={{width: '100%'}}>
                    <List
                        size="small"
                        bordered
                        style={{background: 'rgba(0,0,0,0.03)'}}
                        header={<Title level={5}>Basic Info</Title>}>
                        <Item
                            body={
                                <Link to={`/reports/${report.id}`}>
                                    {report.id}
                                </Link>
                            }
                            title="Id "
                        />
                        <Item
                            body={
                                <Link to={`/user/${report.reporter_id}`}>
                                    {report.reporter_name}
                                </Link>
                            }
                            title="Reporter Name"
                        />
                        {report?.app_bug == false ? (
                            <Item
                                body={
                                    report.order_id ? (
                                        <Link
                                            to={`/booking/${report.order_id}`}>
                                            {report.order_id}
                                        </Link>
                                    ) : (
                                        'N/A'
                                    )
                                }
                                title="Order Id "
                            />
                        ) : (
                            <></>
                        )}
                        {report?.app_bug ? (
                            <Item body={report.os_version} title="Os version" />
                        ) : (
                            <></>
                        )}
                        {report?.app_bug ? (
                            <Item
                                body={report.app_version}
                                title="App version"
                            />
                        ) : (
                            <></>
                        )}
                        {report?.app_bug ? (
                            <Item body={report.model_name} title="Model name" />
                        ) : (
                            <></>
                        )}
                        {report?.app_bug ? (
                            <Item body={report.app_type} title="App Type" />
                        ) : (
                            <></>
                        )}
                        {report?.app_bug ? (
                            <Item
                                body={report.attachments_count}
                                title="Total Attachments"
                            />
                        ) : (
                            <></>
                        )}
                        {report?.attachment?.length ? (
                            report?.attachment?.map(item => {
                                return (
                                    <a
                                        style={{
                                            marginRight: 15,
                                            marginLeft: 15,
                                        }}
                                        href={item}
                                        target="_blank">
                                        View
                                    </a>
                                )
                            })
                        ) : (
                            <></>
                        )}
                        <Item
                            body={report.description || 'N/A'}
                            title="Description"
                        />

                        <Item
                            body={
                                moment(report.created_at).format(
                                    'YYYY-DD-MM (hh:mm A)',
                                ) || 'N/A'
                            }
                            title="Created On"
                        />

                        <Item
                            body={
                                moment(report.updated_at).format(
                                    'YYYY-DD-MM (hh:mm A)',
                                ) || 'N/A'
                            }
                            title="Updated On"
                        />
                        
                        

                    </List>
                </Col>
            </div>
        </>
    )
}

export default ReportView
