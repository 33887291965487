import React, {useState, useEffect} from 'react'
import {Layout, Breadcrumb, Typography, Spin, Switch} from 'antd'
import {useLocation, useNavigate} from 'react-router'
import {isExpired} from 'react-jwt'
import {
    CloseOutlined,
    ConsoleSqlOutlined,
    MenuOutlined,
    UserOutlined,
} from '@ant-design/icons'
import {getToken, removeToken} from 'helpers'
import Routes from './Router'
import Menus from './Menus'
//import Avatar from 'react-avatar';
import {Modal, Button, Form, Input, message} from 'antd'
import {Avatar, Menu, Dropdown} from 'antd'
const {Content, Footer, Sider} = Layout
const {Text, Title} = Typography

function Index() {
    const {search, pathname} = useLocation()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    const [collapsed, setCollapsed] = useState(false)
    // State variable to toggle the dropdown menu
    const [showMenu, setShowMenu] = useState(false)
    const [showModal, setShowModal] = useState(false)

    //
    const [visible, setVisible] = useState(false)
    const [isdebuggerActive,setdebugger]=useState(false)

    const handleMenuClick = e => {
        setVisible(false)
    }

    const handleVisibleChange = async flag => {
        if(flag==true){
            await getdebugger()
        }
        setVisible(flag)
    }

    const handleOpenModal = () => {
        setShowModal(true)
    }

    const getdebugger = async () =>{
         await fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/debugger`,
            {
                method: 'GET',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json',
                }
            },
        ).then(async (res)=>{
            const data =await res.json()
            if(data.success==true){
                setdebugger(data.data)
            }
        })
        
    }

    const changeApiDebugger=async (e)=>{
        await fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/debugger`,
            {
                method: 'PATCH',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json',
                }
            },
        ).then(async (res)=>{
            const data =await res.json()
            if(data.success==true){
                setdebugger(data.data)
            }
        })
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item onClick={handleOpenModal}>Change Password</Menu.Item>
            <Menu.Item>Api Debugger <Switch defaultChecked={isdebuggerActive} onChange={changeApiDebugger} /></Menu.Item>
        </Menu>
    )

    //  // const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false)
    }

    const [form] = Form.useForm()
    const token = localStorage.getItem('token')
    const onFinish = async values => {
        //console.log('Success:', values);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/admin/change_password`,
                {
                    method: 'POST',
                    // headers: {
                    //   'Content-Type': 'application/json',

                    // },
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(values),
                },
            )
            const data = await response.json()

            if (data.success == true) {
                // Add success message
                setShowModal(false)
                message.success(`${data.message}`)
            } else {
                // Add error message
                message.error(`${data.message}`)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
    }

    const validateMessages = {
        required: '${label} is required!',
        types: {
            password: '${label} is not a valid password!',
        },
        whitespace: '${label} cannot be empty!',
        max: '${label} must be at most ${max} characters long!',
        min: '${label} must be at least ${min} characters long!',
    }

    //

    function openSideMenu() {
        setOpen(true)
    }
    function closeSideMenu() {
        setOpen(false)
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (window.innerWidth > 920 && window.innerWidth < 1084) {
                setCollapsed(true)
            } else setCollapsed(false)
        })
        return () => {
            window.removeEventListener('resize')
        }
    }, [])

    // Event handler for the icon click event
    const handleIconClick = () => {
        setShowMenu(!showMenu)
    }

    const User = localStorage.getItem('data')?.replace(/"/g, '')

    useEffect(() => {
        if (getToken() && isExpired(getToken())) {
            removeToken()
            return window.location.reload()
        }
        if (!getToken() && pathname !== '/auth') {
            return navigate('/auth', {state: {from: search}})
        }
        if (getToken() && pathname === '/auth') {
            return navigate('/', {replace: true})
        }
    }, [search, navigate, pathname])

    if (!getToken() && pathname !== '/auth') {
        return (
            <Layout
                style={{
                    height: '100vh',
                    display: 'flex',
                    background: '#fff',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Spin />
            </Layout>
        )
    }
    if (!getToken() && pathname === '/auth') {
        return (
            <Layout
                style={{
                    height: '100vh',
                    display: 'flex',
                    background: '#fff',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Routes />
            </Layout>
        )
    }
    if (getToken() && pathname !== '/auth') {
        return (
            <>
                <header className="header">
                    {open === true ? (
                        <div onClick={closeSideMenu}>
                            <CloseOutlined className="hamburger" />
                        </div>
                    ) : (
                        <div onClick={openSideMenu}>
                            <MenuOutlined className="hamburger" />
                        </div>
                    )}

                    {open && (
                        <div className="header__menu">
                            <Menus closeSideMenu={closeSideMenu} />
                        </div>
                    )}

                    <img style={{width:'120px'}}
                        src="https://s3.amazonaws.com/gopher-test/assets/gopher.png"
                        alt="Gopher go Logo"
                    />
                </header>

                <Layout className="layout">
                    <Sider
                        className="sider"
                        style={{position: 'fixed'}}
                        collapsible
                        collapsed={collapsed}
                        onCollapse={() => setCollapsed(!collapsed)}>
                        <div
                            className="logo"
                            style={{
                                padding: 10,
                                background: '#32D786',
                                position: 'relative',
                            }}>
                            <img
                                style={{paddingTop: collapsed && '10px'}}
                                width={collapsed ? '70' : 'max-content'}
                                height={collapsed ? 'max-content' : '50'}
                                src="https://s3.amazonaws.com/gopher-test/assets/gopher.png"
                                alt="Gopher go Logo"
                            />
                        </div>
                        {/* from menus component all links */}
                        <Menus />
                    </Sider>
                    <Layout className="site-layout" >
                        <Content
                            className={
                                collapsed === true
                                    ? 'main__collap'
                                    : 'main__content'
                            }>
                            <Breadcrumb style={{margin: '16px 0'}}>
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item
                                    style={{textTransform: 'capitalize'}}>
                                    {pathname
                                        .replace('/', '')
                                        .replace('_', ' ')}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <div
                                    style={{
                                        textAlign: 'right',
                                        marginRight: '20px',
                                        marginTop: '-25px',
                                    }}>
                                    <Dropdown
                                        overlay={menu}
                                        trigger={['click']}
                                        visible={visible}
                                        onVisibleChange={handleVisibleChange}>
                                        <Avatar
                                            icon={<UserOutlined />}
                                            size={40}
                                            style={{backgroundColor: '#002140'}}
                                        />
                                    </Dropdown>
                                    <Modal
                                        visible={showModal}
                                        title="Change Password"
                                        closable={false}
                                        onOk={handleCloseModal}
                                        onCancel={handleCloseModal}
                                        okButtonProps={{
                                            style: {display: 'none'},
                                        }}
                                        cancelButtonProps={{
                                            style: {display: 'none'},
                                        }}>
                                        <Form
                                            name="changePassword"
                                            form={form}
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            validateMessages={validateMessages}
                                            layout="vertical">
                                            <Form.Item
                                                name="new_password"
                                                label="New Password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        // min: 8,
                                                        // max: 20,
                                                        // pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/,
                                                        // message:
                                                        // 'Your new password must be between 8 and 20 characters long and contain at least one uppercase letter, one lowercase letter, and one number',
                                                    },
                                                ]}>
                                                <Input.Password />
                                            </Form.Item>
                                            <Form.Item
                                                name="confirm_password"
                                                label="Confirm Password"
                                                dependencies={['new_password']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please confirm your new password!',
                                                    },
                                                    ({getFieldValue}) => ({
                                                        validator(_, value) {
                                                            if (
                                                                !value ||
                                                                getFieldValue(
                                                                    'new_password',
                                                                ) === value
                                                            ) {
                                                                return Promise.resolve()
                                                            }
                                                            return Promise.reject(
                                                                new Error(
                                                                    'The password and confirmation password do not match!',
                                                                ),
                                                            )
                                                        },
                                                    }),
                                                ]}>
                                                <Input.Password />
                                            </Form.Item>
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit">
                                                    Ok
                                                </Button>
                                                <Button
                                                    type="primary"
                                                    style={{marginLeft: '15px'}}
                                                    onClick={handleCloseModal}>
                                                    Cancel
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Modal>
                                </div>
                            <Title
                                style={{textTransform: 'capitalize'}}
                                level={4}>
                                {pathname &&
                                    pathname.replace('/', '').replace('_', ' ')}
                            </Title>
                            <div
                                className="site-layout-background"
                                style={{
                                    padding: 0,
                                    minHeight: 360,
                                    background: '#fff',
                                }}>
                                <Routes />
                            </div>
                        </Content>
                        <Footer
                            style={{
                                textAlign: 'center',
                                // position: 'fixed',
                                // left: 0,
                                // bottom: 0,
                                // width: '100%',
                                // backgroundColor: '#f5f5f5',
                                // color: '#555',
                                // padding: '20px',
                            }}>
                            Gopher, Inc © 2023
                        </Footer>
                    </Layout>
                </Layout>
            </>
        )
    }
}

export default Index
