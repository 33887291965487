import React from 'react'
import {Divider, Menu} from 'antd'
import {Link} from 'react-router-dom'
import './menu.css'
import {
    PieChartOutlined,
    UserOutlined,
    FlagOutlined,
    LogoutOutlined,
    RollbackOutlined,
    BarChartOutlined,
    ShoppingOutlined,
    SecurityScanOutlined,
    ReconciliationOutlined,
    HomeOutlined,
    HomeFilled,
    ShareAltOutlined,
    CheckCircleOutlined,
    LayoutOutlined,
    MessageOutlined,
    DollarOutlined,
    ContainerOutlined,
    DashboardOutlined,
    InboxOutlined,
    NotificationOutlined,
    BellOutlined,
    IssuesCloseOutlined
} from '@ant-design/icons'

function Menus({closeSideMenu}) {
    const User = localStorage.getItem('data')?.replace(/"/g, '')

    return (
        <div className="side-menu">
            <Menu
                onClick={closeSideMenu}
                theme="dark"
                defaultSelectedKeys={['home']}
                mode="vertical"
                className="menu-container">
                <Menu.Item
                    className="ant-menu-items"
                    key="homeuse"
                    icon={<HomeOutlined />}>
                    <Link to="/home"> Home</Link>
                </Menu.Item>
                <Menu.Item
                    className="ant-menu-items"
                    key="dashboard"
                    icon={<DashboardOutlined />}>
                    <Link to="/dashboard">Dashboard</Link>
                </Menu.Item>
                

                <Menu.Item
                    className="ant-menu-items"
                    key="app-notification"
                    icon={<NotificationOutlined />}>
                    <Link to="/app-notification">App Notifications</Link>
                </Menu.Item>
                {/* <Menu.Item key="filter" icon={<DashboardOutlined />}>
                <Link to="/filter">Filter</Link>
            </Menu.Item> */}
                <Menu.Item key="inbox" icon={<InboxOutlined />}>
                    <Link to="/inbox-message">Inbox Message</Link>
                </Menu.Item>

                <Menu.Item key="user" icon={<UserOutlined />}>
                    <Link to="/user"> Users</Link>
                </Menu.Item>

                <Menu.Item key="orders" icon={<ShoppingOutlined />}>
                    <Link to="/orders"> Orders</Link>
                </Menu.Item>

                <Menu.Item key="promocodes" icon={<PieChartOutlined />}>
                    <Link to="/promocodes"> Promo Codes</Link>
                </Menu.Item>
                <Menu.Item key="counter" icon={<RollbackOutlined />}>
                    <Link to="/counter-offers">Counter Offers</Link>
                </Menu.Item>
                <Menu.Item key="bids" icon={<CheckCircleOutlined />}>
                    <Link to="/bids">Bids</Link>
                </Menu.Item>
                <Menu.Item key="gopheroffers" icon={<LayoutOutlined />}>
                    <Link to="/gopher-offers">Gopher Offers</Link>
                </Menu.Item>
                <Menu.Item key="messages" icon={<MessageOutlined />}>
                    <Link to="/messages">In-App Messages</Link>
                </Menu.Item>
                <Menu.Item key="costadjustment" icon={<DollarOutlined />}>
                    <Link to="/cost-adjustment">Cost Adjustment</Link>
                </Menu.Item>
                <Menu.Item key="orderdeclines" icon={<ContainerOutlined />}>
                    <Link to="/order-declines">Order Declines</Link>
                </Menu.Item>
                <Menu.Item key="reports" icon={<BarChartOutlined />}>
                    <Link to="/reports">Reports</Link>
                </Menu.Item>
                <Menu.Item key="ratings" icon={<ReconciliationOutlined />}>
                    <Link to="/ratings">Ratings</Link>
                </Menu.Item>
                <Menu.Item key="otp" icon={<SecurityScanOutlined />}>
                    <Link to="/otp">OTP</Link>
                </Menu.Item>
                <Menu.Item
                    className="ant-menu-items"
                    key="otp-blocked"
                    icon={<IssuesCloseOutlined />}>
                    <Link to="/otp-blocked">Otp Blocked</Link>
                </Menu.Item>
                <Menu.Item key="address" icon={<HomeFilled />}>
                    <Link to="/address">Address</Link>
                </Menu.Item>
                <Menu.Item key="flags" icon={<FlagOutlined />}>
                    <Link to="/flags">Flags</Link>
                </Menu.Item>
                <Menu.Item key="referral" icon={<ShareAltOutlined />}>
                    <Link to="/referral">Referral</Link>
                </Menu.Item>
                <Menu.Item
                    className="ant-menu-items"
                    key="webhooks"
                    icon={<UserOutlined />}>
                    <Link to="/webhooks">Webhooks</Link>
                </Menu.Item>
                <Menu.Item
                    className="ant-menu-items"
                    key="OOA_Broadcast"
                    icon={<BellOutlined />}>
                    <Link to="/OOA_Unsubscribed">OOA Unsubscribed</Link>
                </Menu.Item>
                <Menu.Item
                    className="ant-menu-items"
                    key="connected-user"
                    icon={<UserOutlined />}>
                    <Link to="/connected-user">Connected User</Link>
                </Menu.Item>
                <Menu.Item
                    style={{marginBottom: '50px'}}
                    key="10"
                    icon={<LogoutOutlined />}
                    onClick={() => {
                        localStorage.clear()
                        window.location.reload()
                        window.location.href = '/auth'
                    }}>
                    Logout
                </Menu.Item>
                <Menu.Item style={{color: '#ffffff'}} key="11">
                    {User}
                </Menu.Item>
            </Menu>
        </div>
    )
}

export default Menus
