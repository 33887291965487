export const QUERIES = {
    // BOOKINGS
    GET_ALL_BOOKINGS: 'GET_ALL_BOOKINGS',
    GET_BOOKINGS_BY_FILTERS: 'GET_BOOKINGS_BY_FILTERS',

    // AUTH
    LOGIN: 'LOGIN',
    SIGNUP: 'SIGNUP',

    //INBOX MESSAGE
    GET_ALL_FILTER_MESSAGES: 'GET_ALL_FILTER_MESSAGES',

    // USERS
    GET_ALL_USERS: 'GET_ALL_USERS',

    // COUPONS
    GET_ALL_COUPONS: 'GET_ALL_COUPONS',
    GET_COUPON_BY_ID: 'GET_COUPON_BY_ID',

    // Counter Offers

    GET_ALL_COUNTER: 'GET_ALL_COUNTER',
    GET_COUNTER_BY_ID: 'GET_COUNTER_BY_ID',

    GET_ALL_RATING: 'GET_ALL_RATING',
    GET_RATING_BY_ID: 'GET_RATING_BY_ID',

    GET_ALL_REPORT: 'GET_ALL_REPORT',
    GET_REPORT_BY_ID: 'GET_REPORT_BY_ID',

    GET_ALL_OTP: 'GET_ALL_OTP',
    GET_OTP_BY_ID: 'GET_OTP_BY_ID',

    GET_ALL_ADDRESS: 'GET_ALL_ADDRESS',
    GET_ADDRESS_BY_ID: 'GET_ADDRESS_BY_ID',

    GET_ALL_FLAGS: 'GET_ALL_FLAGS',
    GET_FLAGS_BY_ID: 'GET_FLAGS_BY_ID',

    // REFERRAL
    GET_ALL_REFERRAL: 'GET_ALL_REFERRAL',

    //BIDS
    GET_ALL_BIDS: 'GET_ALL_BIDS',
    GET_BIDS_BY_ID: 'GET_BIDS_BY_ID',

    //GOPHER OFFERS

    GET_ALL_GOPHER_OFFER: 'GET_ALL_GOPHER_OFFER',
    GET_ALL_GOPHER_OFFER_BY_ID: 'GET_ALL_GOPHER_OFFER_BY_ID',

    //ADD-IN-APP MESSAGES

    GET_ALL_MESSAGES: 'GET_ALL_MESSAGES',
    GET_ALL_MESSAGES_BY_ID: 'GET_ALL_MESSAGES_BY_ID',

    //COST ADJUSTMENT
    GET_ALL_COST_ADJUSTMENT: 'GET_ALL_COST_ADJUSTMENT',
    GET_ALL_COST_ADJUSTMENT_BY_ID: 'GET_ALL_COST_ADJUSTMENT_BY_ID',

    //ORDER DECLINES
    GET_ALL_ORDER_DECLINES: 'GET_ALL_ORDER_DECLINES',
    GET_ALL_ORDER_DECLINES_BY_ID: 'GET_ALL_ORDER_DECLINES_BY_ID',


    //CONNECTED USER
    GET_ALL_CONNECTED_USER:'GET_ALL_CONNECTED_USER',
    GET_ALL_CONNECTED_USER_BY_ID:'GET_ALL_CONNECTED_USER_BY_ID',

    //WEBHOOK
    GET_ALL_WEBHOOK:'GET_ALL_WEBHOOK'
}

export const ORDER_STATUS = {
    PENDING: 'pending',
    ACCEPTED: 'accepted',
    PICKED_UP: 'picked_up',
    DELIVERED: 'delivered',
    EXPIRED: 'expired',
    CANCELLED: 'cancelled',
}
