import * as API from '_axios'
import {ADMIN_URLS} from 'constants/url'

export const getAllCounter = async ({queryKey}) => {
    const [, pageNo,filters] = queryKey
    console.log(filters.requestorName)
    const result = await API.post(
        `${ADMIN_URLS.getCounter}?pageNo=${pageNo || 0}`,
        {
            requester_name: filters.requestorName || null,
            gopher_name: filters.gopherName || null,
            order_title: filters.title || null,
            
        }
        
    )
    
    return result
    
}



export const getCounterById = async ({queryKey}) => {
    const [, id] = queryKey
    const result = await API.get(`${ADMIN_URLS.getCounterById}/${id}`)
    return result
}

