import {Form} from 'antd'
import styled from 'styled-components'

export const Root = styled.div`
    padding: 20px;
`

export const CreateForm = styled(Form)`
    max-width: 700px;
    margin: 0 auto;
`
