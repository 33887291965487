import React from 'react'
import {useParams} from 'react-router'
import {Link} from 'react-router-dom'
import {Spin} from 'antd'
import UserView from './components/UserView'
import CounterView from './components/CounterView'
import BookingView from './components/BookingView'
import * as Styled from './details-styled'
import RatingView from './components/RatingView'
import ReportView from './components/ReportView'
import OtpView from './components/OtpView'
import AddressView from './components/AddressView'
import FlagView from './components/FlagView'
import EditUserView from './components/EditUserView'
import BidsView from './components/BidsView'
import GopherOfferView from './components/GopherOfferView'
import MessagesView from './components/MessagesView'
import CostAdjustmentView from './components/CostAdjustmentView'
import OrderDeclinesView from './components/OrderDeclinesView'
import Edit from '../../containers/Edit/index'
import EditMessage from '../../containers/Edit/editMessage'

function DetailedView() {
    const params = useParams()

    let content = <></>

    switch (params.type) {
        case 'user':
            content = <UserView params={params} />
            break
        case 'booking':
            content = <BookingView params={params} />
            break
        case 'counter-offer':
            content = <CounterView params={params} />
            break
        case 'ratings':
            content = <RatingView params={params} />
            break
        case 'reports':
            content = <ReportView params={params} />
            break
        case 'otp':
            content = <OtpView params={params} />
            break
        case 'address':
            content = <AddressView params={params} />
            break
        case 'flags':
            content = <FlagView params={params} />
            break
        case 'edit-user':
            content = <EditUserView params={params} />
            break
        case 'bids':
            content = <BidsView params={params} />
            break
        case 'gopher-offers':
            content = <GopherOfferView params={params} />
            break
        case 'messages':
            content = <MessagesView params={params} />
            break
        case 'cost-adjustment':
            content = <CostAdjustmentView params={params} />
            break
        case 'order-declines':
            content = <OrderDeclinesView params={params} />
            break
        case 'edit-inbox':
            content = <Edit params={params} />
            break
        case 'edit-inboxMessage':
            content = <EditMessage params={params} />
            break
        default:
            content = <Link to="/">Go to home</Link>
    }

    return <Styled.Root>{params?.id ? content : null}</Styled.Root>
}

export default DetailedView
