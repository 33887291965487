import * as API from '_axios'
import { ADMIN_URLS } from 'constants/url'

export const getAllBids =async({queryKey})=>{
    const [, pageNo,filters]=queryKey
    const result=await API.post(
        `${ADMIN_URLS.getBids}?pageNo=${pageNo || 0}`,
        {
            requester_name: filters.requestorName || null, 
            gopher_name: filters.gopherName || null, 
            title: filters.title || null, 
        }

    )
    
    return result
    
}

//get bids by id
export const getBidsById = async ({queryKey}) =>{
    const [, id]=   queryKey
    const result = await API.get(`${ADMIN_URLS.getBidsById}/${id}`)
    console.log(result);
    return result
}

