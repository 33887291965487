import React from 'react'
import moment from 'moment'
import { Col, Row, List, Tag, Typography } from 'antd'
import { Spin } from 'antd'
import { useQuery } from 'react-query'

import Item from './Item'
import * as orderDeclinesQueries from '../../../queries/orderdeclines.queries'
import * as Styled from './counterview.styled'
import { Link } from 'react-router-dom'

const { Title } = Typography

function OrderDeclinesView({ params }) {
    const orderDeclinesQuery = useQuery(['id', params.id], orderDeclinesQueries.getOrderDeclinesById)

    const declines = orderDeclinesQuery.data?.data.data

    
    if (orderDeclinesQuery.isLoading) {
        return (
            <Styled.Root>
                <Spin />
            </Styled.Root>
        )
    }

    return (
        <>  
            <div className="order">
                <Col className="order_col">
                    <List
                        size="small"
                        bordered
                        style={{
                            background: 'rgba(0,0,0,0.03)',
                            paddingTop: '1.05rem',
                        }}
                        header={<Title level={5}>Basic Info</Title>}>
                        
                        <Item body={declines.id} title="ID#" />
                        <Item body={<Link to={`/booking/${declines.order_id}`} >{declines.order_id}</Link>}  title="Order ID " />
                        <Item body={<Link to={`/user/${declines.gopher_id}`} >{declines.gopher}</Link>}  title="Gopher Name" />
                        <Item
                            body={moment(declines.createdon).format(
                                'YYYY-DD-MM',
                            )}
                            title="Created On"
                        />
            
                    </List>
                </Col>
                
                
            </div>
        </>
    )
}

export default OrderDeclinesView
