import React, {lazy, Suspense} from 'react'
import {Routes, Route} from 'react-router'
import {Spin} from 'antd'
import DetailedView from './DetailedView'
import CounterOffer from './CounterOffers'
import Reports from './Reports/Reports'
import Otp from './Otp/Otp'
import Ratings from './Ratings/Ratings'
import Address from './Address/Address'
import Flags from './Flags/Flags'
import Webhook from './Webhook/Webhook'
import OOA from './OOA_Broadcast/OOA'
import BlockedUser from './Blocked_users'

const Home = lazy(() => import(`containers/Home`))
const Bookings = lazy(() => import(`containers/Bookings`))
const Users = lazy(() => import(`containers/Users`))
const CreateUsers = lazy(() => import(`containers/CreateUser`))
const Auth = lazy(() => import(`containers/Auth`))
const Coupons = lazy(() => import(`containers/Coupons`))
const Referral = lazy(() => import(`containers/Referral`))
const Bids = lazy(() => import(`containers/Bids`))
const GopherOffer = lazy(() => import(`containers/GopherOffer`))
const Messages = lazy(() => import(`containers/Messages`))
const CostAdjustment = lazy(() => import(`containers/CostAdjustment`))
const OrderDeclines = lazy(() => import(`containers/OrderDeclines`))
const Dashboard = lazy(() => import(`containers/Dashboard`))
const Broadcast = lazy(() => import(`containers/Broadcast`))
const Filter = lazy(() => import(`containers/Filter`))
const InboxMessage = lazy(() => import(`containers/InboxMessage`))
const Edit = lazy(() => import(`containers/Edit/index`))
const EditMessage = lazy(() => import(`containers/Edit/editMessage`))
const ConnectedUser = lazy(() => import(`containers/ConnectedUser`))

const lazyComponent = component => {
    return <Suspense fallback={<Spin />}>{component}</Suspense>
}

function GopherRouter() {
    return (
        <Routes path="/">
            <Route index element={lazyComponent(<DetailedView />)} />
            <Route path="home" element={lazyComponent(<DetailedView />)} />
            <Route path="orders" element={lazyComponent(<Home />)} />
            <Route
                path="create_user"
                element={lazyComponent(<CreateUsers />)}
            />
            <Route path="user" element={lazyComponent(<Users />)} />
            <Route path="booking" element={lazyComponent(<Bookings />)} />
            <Route path="auth" element={lazyComponent(<Auth />)} />
            <Route path="promocodes" element={lazyComponent(<Coupons />)} />
            <Route path="reports" element={lazyComponent(<Reports />)} />
            <Route path="ratings" element={lazyComponent(<Ratings />)} />
            <Route path="otp" element={lazyComponent(<Otp />)} />
            <Route
                path="counter-offers"
                element={lazyComponent(<CounterOffer />)}
            />
            <Route path="address" element={lazyComponent(<Address />)} />
            <Route path="flags" element={lazyComponent(<Flags />)} />
            <Route path="referral" element={lazyComponent(<Referral />)} />
            <Route path="bids" element={lazyComponent(<Bids />)} />
            <Route
                path="gopher-offers"
                element={lazyComponent(<GopherOffer />)}
            />
            <Route path="messages" element={lazyComponent(<Messages />)} />
            <Route
                path="cost-adjustment"
                element={lazyComponent(<CostAdjustment />)}
            />
            <Route
                path="order-declines"
                element={lazyComponent(<OrderDeclines />)}
            />
            <Route path="dashboard" element={lazyComponent(<Dashboard />)} />
            <Route
                path="app-notification"
                element={lazyComponent(<Broadcast />)}
            />

            <Route path="filter" element={lazyComponent(<Filter />)} />
            <Route
                path="inbox-message"
                element={lazyComponent(<InboxMessage />)}
            />
            <Route path="edit-inbox" element={lazyComponent(<Edit />)} />
            <Route
                path="edit-inboxMessage"
                element={lazyComponent(<EditMessage />)}
            />

            <Route
                path="/:type/:id"
                element={lazyComponent(<DetailedView />)}
            />
            <Route
                path="connected-user"
                element={lazyComponent(<ConnectedUser />)}
            />
            <Route
                path="webhooks"
                element={lazyComponent(<Webhook />)}
            />
            <Route
                path="OOA_Unsubscribed"
                element={lazyComponent(<OOA />)}
            />
             <Route
                path="otp-blocked"
                element={lazyComponent(<BlockedUser />)}
            />

            <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
    )
}

export default GopherRouter
