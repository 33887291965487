import React, {useState, useEffect} from 'react'
import {Typography, Tag, Button, Space, Input, Row} from 'antd'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {useQuery} from 'react-query'

import * as Queries from 'queries/counter.queries'
import {QUERIES} from 'constants/index'
import DataTable from 'components/DataTable'

import * as Styled from './counter-styles'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { ReloadOutlined } from '@ant-design/icons'

const {Title} = Typography

function CounterOffer() {
    const columns = [
        {
            title: '#ID',
            dataIndex: 'id',
            key: 'offerId',
            render: val => <Link to={`/counter-offer/${val}`}>{val}</Link>,
            sorter: (a, b) => a.id - b.id,
            // width: 110,
        },
        {
            title: 'Order Id',
            dataIndex: 'order_id',
            key: 'order_id',
            render: val => <Link to={`/booking/${val}`}>{val}</Link>,
        },
        {
            title: 'Order',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Gopher',
            key: 'gopher',
            render: val => (
                <Link to={`/user/${val.gopher_id}`}>{val.gopher}</Link>
            ),
        },
        {
            title: 'Requestor',

            key: 'requestor',
            render: val => (
                <Link to={`/user/${val.requestor_id}`}>{val.requestor}</Link>
            ),
        },
        {
            title: 'Offer ($)',
            dataIndex: 'order_offer',
            key: 'order_offer',
            render: val => val / 100,
        },

        {
            title: 'Counter Offer ($)',
            dataIndex: 'counter_offer',
            key: 'counter_offer',
            render: val => val / 100,
        },
        {
            title: 'Cost of Goods ($)',
            dataIndex: 'order_cog',
            key: 'order_cog',
            render: val => val / 100,
        },
        {
            title: 'COG Counter ($)',
            dataIndex: 'counter_cog',
            key: 'counter_cog',
            render: val => val / 100,
        },
        {
            title: 'Comments',
            dataIndex: 'comment',
            key: 'comment',
            render: val => val || 'N/A',
        },
        {
            title: 'Created On',
            dataIndex: 'created_at',
            key: 'created_at',
            render: val => moment(val).format('YYYY-DD-MM (hh:mm A)') || 'N/A',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: val =>
                val === 'Declined' ? (
                    <Tag color="red">{val}</Tag>
                ) : (
                    <Tag color="green">{val}</Tag>
                ),
        },
    ]

    const [enabled, setEnabled] = useState(false)
    const [filters, setFilters] = useState({
        requestorName: '',
        gopherName: '',
        title: '',
    })
    const filterQuery = useQuery(
        [QUERIES.GET_ALL_COUNTER, 0, filters],
        Queries.getAllCounter,
        {
            staleTime: 180000,
            filters: enabled,
            enabled,
        },
    )

    /*
     * function to update the whole filtering data in the user page
     * {param} key: any string value representing the property of filters variable which is to be updated
     * {param} value: the value of that particular property of the filter
     */
    const onFiltersChange = (key, value) => {
        setFilters(values => {
            console.log(value)
            return {
                ...values,
                [key]: value,
            }
        })
    }
    const getAndSaveUserData = () => {
        filterQuery.refetch({
            queryKey: [QUERIES.GET_ALL_COUNTER, 0, filters],
        })
    }
    // a function which will be used to clear all the filters to search user
    const onResetFilters = () => {
        setFilters({
            requestorName: '',
            gopherName: '',
            telephone: '',
        })
    }

    useEffect(() => {
        if (filters.requestorName || filters.gopherName || filters.title)
            setEnabled(true)
        else setEnabled(false)
    }, [filters])

    // function to export csv

    const [csv, setCsv] = useState(false)

    function downloadCsv(e) {
        e.preventDefault()
        setCsv(true)
        const token = localStorage.getItem('token')
        const csvDate = moment(new Date()).format('DD_MM_YYYY')
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/admin/counter_offer/csv`, {
                headers: {
                    Authorization: token,
                },
            })
            .then(res => {
                fileDownload(res.data, `Counter_Offer_${csvDate}.csv`)
                setCsv(false)
            })
            .catch(err => {
                console.log(err)
                setCsv(false)
            })
    }

    function reloadApi(){
        filterQuery.refetch({
            queryKey : [QUERIES.GET_ALL_COUNTER,0,filters],
        })
    }

    return (
        <>
        <div style={{ textAlign: 'right', marginRight: '30px', marginBottom: '5px' }}> 
            <Button icon={<ReloadOutlined />} style={{textAlign:'right'}}   onClick={reloadApi} type="primary">Reload</Button>
        </div>
        <div className="addess__page">
            <Styled.FilterCollapse bordered ghost>
                <Styled.FilterCollapsePanel
                    header={<Title level={5}>Filters (click to toggle)</Title>}>
                    <Space direction="vertical">
                        {/* email filtering inputs */}
                        <Row align="middle" gutter={10}>
                            <Space direction="horizontal">
                                <Input
                                    allowClear
                                    value={filters.gopherName}
                                    placeholder="Gopher Name"
                                    onChange={e =>
                                        onFiltersChange(
                                            'gopherName',
                                            e.target.value,
                                        )
                                    }
                                />
                                <Input
                                    allowClear
                                    value={filters.requestorName}
                                    placeholder="Requestor Name "
                                    onChange={e =>
                                        onFiltersChange(
                                            'requestorName',
                                            e.target.value,
                                        )
                                    }
                                />

                                <Input
                                    allowClear
                                    value={filters.title}
                                    placeholder="Order Title"
                                    onChange={e =>
                                        onFiltersChange('title', e.target.value)
                                    }
                                />

                                <Button
                                    disabled={!Object.keys(filters).length > 0}
                                    onClick={getAndSaveUserData}
                                    type="primary">
                                    Search
                                </Button>
                                <Button
                                    disabled={!Object.keys(filters).length > 0}
                                    onClick={onResetFilters}
                                    type="ghost"
                                    danger>
                                    Reset Filters
                                </Button>
                            </Space>
                        </Row>
                    </Space>
                </Styled.FilterCollapsePanel>
            </Styled.FilterCollapse>

            <DataTable
                columns={columns}
                pagination={true}
                filters={filters}
                queryFunction={Queries.getAllCounter}
                queryKey={QUERIES.GET_ALL_COUNTER}
            />

            <Button
                style={{margin: '2rem', minHeight: '45px', display: 'flex'}}
                onClick={e => downloadCsv(e)}
                loading={csv}
                type="light">
                <img
                    style={{width: '35px', height: '35px', marginRight: '5px'}}
                    src="/csb.png"
                    alt=""
                />
                Download CSV
            </Button>
        </div>
      </>
    )
}

export default CounterOffer
