import axios from 'axios'
import {getToken} from 'helpers'

let  baseURL = process.env.REACT_APP_BASE_URL
// if (process.env.NODE_ENV === 'development') {
//     baseURL = process.env.BASE_URL_LOCAL
   
// }

const client = axios.create({
    baseURL,
    headers: {
        authorization: getToken(),
    },
})

const request = async ({method = 'get', url, headers, payload, skipToken}) => {
    if (skipToken) delete client.defaults.headers.common['authorization']
    return await client.request({
        url,
        headers,
        data: payload,
        method,
    })
}

client.interceptors.response.use(
    response => {
        if (response.data) {
            return response.data
        }
    },
    error => {
        if (error.response?.data.message) {
            return Promise.reject({
                message: error.response.data.message,
                data: JSON.stringify(error.response.data),
            })
        }
        return Promise.reject({
            message: error.message,
            data: JSON.stringify(error),
        })
    },
)

export const post = (url, data, skipToken) => {
    const payload = {...data}
    let headers = {}
    let method = 'post'
    return request({method, url, payload, headers, skipToken})
}

export const get = (url, skipToken) => {
    let headers = {}
    return request({method: 'get', url, headers, skipToken})
}

export const put = (url, data, skipToken) => {
    const payload = {...data}
    let method = 'put'
    return request({method, url, payload, skipToken})
}

export const delete_ = (url, data, skipToken) => {
    const payload = {...data}
    let method = 'delete'
    return request({method, url, payload, skipToken})
}

const defaults = {put, get, post, delete_}
export default defaults
