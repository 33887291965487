import * as API from '_axios'
import {ADMIN_URLS} from 'constants/url'

export const getAllWebHooks = async () => {

    const result = await API.get(
        `${ADMIN_URLS.getWebhooks}`
    )
    return result
}

export const deleteWebHookById = async (id) => {

    const result = await API.delete_(
        `${ADMIN_URLS.deleteWebHookById}/${id}`
    )
    return result
}

export const createWebHook = async (event,url) => {

    const result = await API.post(
        `${ADMIN_URLS.deleteWebHookById}`,
        {event,url}
    )
    return result
}

export const updateWebHookById = async (id,event,url) => {

    const result = await API.put(
        `${ADMIN_URLS.updateWebHookById}/${id}`,
        {event,url}
    )
    return result
}