import * as API from '_axios'
import {ADMIN_URLS} from 'constants/url'

export const getAllAddresses = async ({queryKey}) => {
    const [, pageNo, filters] = queryKey
    const result = await API.post(
        `${ADMIN_URLS.getAddress}?pageNo=${pageNo || 0}`
    )
    return result
    
}

export const getAddressById = async ({queryKey}) => {
    const [, id] = queryKey
    const result = await API.get(`${ADMIN_URLS?.getAddress}/${id}`)
    return result
}




