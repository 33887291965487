import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    Pagination,
    Row,
    Table,
    Tag,
    message,
} from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router'
import {Link} from 'react-router-dom'
import './index.css'
import {SearchOutlined} from '@ant-design/icons'

function Edit({params}) {
    const [sendMessageForm] = Form.useForm()
    const [users, setUsers] = useState([])
    const [editUser, setEditUser] = useState({})
    const [currentPage, setCurrentPage] = useState(1) // State to track the current page number
    const [pageSize, setPageSize] = useState(50) // State to track the number of items per pag
    const [searchText, setSearchText] = useState('')
    const navigate = useNavigate()

    const token = localStorage.getItem('token')
    const fetchData = async () => {
        try {
            const config = {
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json',
                },
            }
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/admin/inbox_message/send_message/${params.id}`,
                config,
            )
            console.log(response.data.data)
            setEditUser(response.data.data.inbox_message)
            // setUpdateUser(response.data.data.inbox_message)
            setUsers(response.data.data.message_sent_to)
            // setExpiredOn(response.data.data.inbox_message.expired_on)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleSubmit = values => {
        const formattedDate = values.date
            ? moment(values.date).format('YYYY-MM-DD HH:mm:ss Z')
            : null
        const formData = {...values, date: formattedDate}
        // console.log('Form values:', formData)

        sendMessage(formData) // Send the combined data
    }

    const defaultDate = moment(editUser.expired_on)

    const initialValues = {
        subject: editUser.subject,
        body: editUser.body,
        // expired_on: defaultDate,
    }

    const sendMessage = async values => {
        const token = localStorage.getItem('token')
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/admin/inbox_message/send_message/${params.id}`,
                {
                    method: 'PUT',
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(values),
                },
            )
            const data = await response.json()
            if (data.success == true) {
                sendMessageForm.resetFields()

                // setEditUser()
                // Redirect to another route
                message.success('Update successfully')

                navigate('/inbox-message')
            } else {
                // Add error message
            }
        } catch (error) {
            console.error(error)
        }
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'userId',
            render: val => <Link to={`/user/${val}`}>{val}</Link>,
            sorter: (a, b) => a.id - b.id,
            width: 100,
        },
        {
            title: 'User Role',
            dataIndex: 'role_id',
            key: 'role_id',
            render: val => {
                return (
                    <>
                        <Tag
                            color={val ? (val === 2 ? 'cyan' : 'green') : null}>
                            {val === 2
                                ? 'Gopher'
                                : val === 3
                                ? 'Requester'
                                : val === 1
                                ? 'Admin'
                                : null}
                        </Tag>
                    </>
                )
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Telephone',
            dataIndex: 'telephone',
            key: 'telephone',
        },
        {
            title: 'Full name',
            key: 'firstName',
            render: val => (
                <Link to={`/user/${val.id}`}>
                    {val.first_name} {val.last_name}
                </Link>
            ),
        },
        {
            title: 'Viewed',
            dataIndex: 'viewed',
            key: 'viewed',
            render: val => {
                return (
                    <>
                        <Checkbox checked={val ? true : false} />
                    </>
                )
            },
        },
    ]

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page)

        setPageSize(pageSize)
    }

    const handleGoToNext = () => {
        setCurrentPage(prevPage => prevPage + 1)
    }

    // console.log('current page ==>', currentPage)

    // Filter the data based on the search text
    // const filteredData = users.filter(
    //     item =>
    //         item.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
    //         item.last_name.toLowerCase().includes(searchText.toLowerCase()) ||
    //         item.email.toLowerCase().includes(searchText.toLowerCase()) ||
    //         item.telephone.toLowerCase().includes(searchText.toLowerCase()) ||
    //         item.id.toLowerCase().includes(searchText.toLowerCase()),
    // )

    const paginatedDataSource = users.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize,
    )

    // const endIndex = editUser.expired_on ? editUser.expired_on.length : ''
    // const formateDate = editUser.expired_on
    //     ? editUser.expired_on.substring(0, endIndex)
    //     : ''

    // const dateString = formateDate
    // const date = new Date(dateString)
    // const formattedString = date.toLocaleString()

    // const formattedString2 = moment(dateString).format('YYYY-MM-DD')

    // const editUser1 = {
    //     // Other properties...
    //     // 2023-06-21T05:29:26.440Z, '2023-06-20T06:57:00.757Z'

    //     // expired_on: '2023-06-20T06:57:00.757Z',
    //     expired_on: editUser.expired_on,
    // }

    const onValuesChange = (changedValues, allValues) => {
        if ('expired_on' in changedValues) {
            //update the editUser state with the new value
            setEditUser({...editUser, expired_on: changedValues})
        }
    }

    const handleSearch = e => {
        setSearchText(e.target.value)
        setCurrentPage(1) // Reset to the first page when searching
    }

    return (
        <div>
            <Row gutter={[6, 6]} className=" p-4 mx-6">
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    className="h-[30rem] md:h-[25rem]">
                    <div>
                        <div
                            style={{
                                background: 'rgba(0,0,0,0.03)',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '10px',
                                height: 'auto', // Remove the fixed height
                                marginBottom: '16px', // Add margin bottom for spacing
                            }}>
                            <Form
                                className="md:h-[23rem]"
                                labelCol={{
                                    span: 5,
                                }}
                                wrapperCol={{
                                    span: 19, // Adjust the wrapper column span to fill the available space
                                }}
                                layout="horizontal"
                                style={{
                                    maxWidth: '100%', // Remove the fixed maximum width
                                    marginTop: '10px',
                                }}
                                initialValues={initialValues}
                                onValuesChange={onValuesChange}
                                onFinish={handleSubmit}
                                form={sendMessageForm}>
                                <Form.Item
                                    label="Subject"
                                    name="subject"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a subject',
                                        },
                                    ]}>
                                    <textarea
                                        defaultValue={editUser.subject}
                                        style={{width: '99%'}}
                                        rows={1}
                                        className="p-1"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Body"
                                    name="body"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a body',
                                        },
                                    ]}>
                                    <textarea
                                        defaultValue={editUser.body}
                                        rows={4}
                                        style={{width: '99%'}}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Expired On"
                                    name="expired_on"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select an expiration date',
                                        },
                                    ]}>
                                    <DatePicker
                                        style={{width: '100%'}}
                                        format="YYYY-MM-DD"
                                        defaultValue={defaultDate}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{marginLeft: 50}}>
                                        Update Message
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div>
                        <Row gutter={[16, 16]}>
                            {/* {users.length > 10 && (
                                <Col span={24}>
                                    <Input.Search
                                        placeholder="Search by fullname, email, telephone or id"
                                        value={searchText}
                                        onChange={handleSearch}
                                        style={{marginBottom: 16}}
                                        prefix={<SearchOutlined />}
                                    />
                                </Col>
                            )} */}
                            <Col span={24}>
                                <div>
                                    <Table
                                        columns={columns}
                                        dataSource={paginatedDataSource}
                                        pagination={false}
                                        showHeader={true}
                                    />
                                </div>
                            </Col>
                            <Col span={24}>
                                <Pagination
                                    current={currentPage}
                                    pageSize={pageSize}
                                    total={users.length}
                                    onChange={handlePageChange}
                                    showSizeChanger
                                    showTotal={(total, range) =>
                                        `${range[0]}-${range[1]} of ${total} message`
                                    }
                                    showNextButton
                                    onNext={handleGoToNext}
                                    className="my-pagination"
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Edit
