import {Collapse} from 'antd'
import styled from 'styled-components'

export const Root = styled.div``

export const TopActions = styled.div`
    background: #001628;
    padding: 10px 20px;
    border: 1px solid #001628;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 0 0;
`

export const Table = styled.div``

export const FilterCollapse = styled(Collapse)`
    border: 1px dotted #000;
    background: rgb(165, 164, 164);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
`

export const FilterCollapsePanel = styled(Collapse.Panel)`
    background: #fefefe;
`
