import * as API from '_axios'
import {ADMIN_URLS} from 'constants/url'

export const getAllRating = async ({queryKey}) => {
    const [, pageNo, filters] = queryKey
    const result = await API.post(
        `${ADMIN_URLS.getRatings}?pageNo=${pageNo || 0}`,
        {
            rater_name: filters.raterName || null,
            rated_name: filters.ratedName || null,
            score: filters.score || null,
        },
    )
    return result
}

export const getRatingById = async ({queryKey}) => {
    const [, id] = queryKey
    console.log(id)
    const result = await API.get(`${ADMIN_URLS.getRatingsById}/${id}`)
    return result
}
