import React, { useState } from 'react'
import moment from 'moment'
import { Col, Row, List, Tag, Typography, Select, Button } from 'antd'
import { Spin, message } from 'antd'
import { useMutation, useQuery } from 'react-query'

import Item from './Item'
import * as ratingsQueries from '../../../queries/ratings.queries'
import * as Styled from './counterview.styled'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';

const { Title } = Typography

function RatingView({ params }) {
    const ratingQuery = useQuery(
        ['id', params.id],
        ratingsQueries.getRatingById,
    )

    const RATING = [
        { label: 'One', value: '1' },
        { label: 'Two', value: '2' },
        { label: 'Three', value: '3' },
        { label: 'Four', value: '4' },
        { label: 'Five', value: '5' }
    ];
    const [isRatingDrop, setIsRatingDrop] = useState(false);
    const [ratingValue, setRatingValue] = useState({
        newRating: '',
    });
    const onFiltersChange = (key, value) => {
        console.log(key, value, 'keyvalue')
        setRatingValue({
            ...ratingValue,
            [key]: value,
        })
        console.log(ratingValue, 'ratingValue')
    };
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');
    const navigate = useNavigate()
    const changeRating = () => {
        setLoading(true);
        const data={
            score:ratingValue.newRating
        }
        axios
            .put(
                `${process.env.REACT_APP_BASE_URL}/admin/rating/${params.id}`,
                data,
                {
                    headers: {
                        Authorization: token,
                    },
                }
            )
            .then(res => {
                if (res?.data?.success) {
                    message.success(res.data.message);
                    setIsRatingDrop(false);
                    rating.score=ratingValue.newRating;
                    navigate(`/ratings/${params.id}`);

                } else {
                    message.error(res.data.message);
                }
            })
            .catch(error => {
                message.error(error?.response?.data?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    
    const rating = ratingQuery.data?.data.data
    if (ratingQuery.isLoading) {
        return (
            <Styled.Root>
                <Spin />
            </Styled.Root>
        )
    }

    return (
        <>
            <div className="order">
                <Col style={{ width: '100%' }}>
                    <List
                        size="small"
                        bordered
                        style={{ background: 'rgba(0,0,0,0.03)' }}
                        header={<Title level={5}>Basic Info</Title>}>
                        <Item
                            body={
                                <Link to={`/ratings/${rating.id}`}>
                                    {rating.id}
                                </Link>
                            }
                            title="Id "
                        />
                        <Item
                            body={
                                <Link to={`/booking/${rating.order_id}`}>
                                    {rating.order_id}
                                </Link>
                            }
                            title="Order Id "
                        />
                        <Item
                            body={
                                <Link to={`/user/${rating.rated_id}`}>
                                    {rating.rated_name}
                                </Link>
                            }
                            title="Rated Name "
                        />

                        <Item
                            body={
                                <Link to={`/user/${rating.rater_id}`}>
                                    {rating.rater_name}
                                </Link>
                            }
                            title="Rater Name"
                        />
                        <Item body={rating.score || 'N/A'} title="Score" />
                        {loading && <div>Loading...</div>}
                        {isRatingDrop&&(<Select
                            value={ratingValue.newRating}
                            onChange={value => onFiltersChange('newRating', value)}
                            style={{ width: 150 }}>
                            <Select.Option value="">OPTIONS</Select.Option>
                            {RATING.map(status => (
                                <Select.Option key={status.value} value={status.value}>{status.label}</Select.Option>
                            ))}
                        </Select>)}
                        <Button type="primary" onClick={() => {
                            setIsRatingDrop(true);
                            if(isRatingDrop){
                                changeRating();
                            }
                            
                        }
                        } title="Change">
                            {isRatingDrop ? 'Submit' : 'Change Score'}
                        </Button>


                        <Item body={rating.review || 'N/A'} title="Review" />
                    </List>
                </Col>
            </div>
        </>
    )
}

export default RatingView
