import React, {useState, useEffect} from 'react'
import {Typography, Button, Space, Input, Row, Tag, Table, Modal, Select, message, Pagination} from 'antd'
import {Link} from 'react-router-dom'
import {AppstoreAddOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, PlusCircleFilled} from '@ant-design/icons'
import moment from 'moment'
import {useQuery} from 'react-query'

import * as Queries from 'queries/broadcast.queries'
import {QUERIES} from 'constants/index'
import DataTable from 'components/DataTable'

import * as Styled from './OOA.styled'
import axios from 'axios'
import fileDownload from 'js-file-download'
import {ReloadOutlined} from '@ant-design/icons'
import { render } from '@testing-library/react'

const {Title} = Typography

function OOA() {
    const [users,setUsers]=useState([])
    const [pageNo,setPageNo]=useState(0)
    const [totalrecords,setTotalRecords]=useState(0)
    const [csvloader,setCsvLoader]=useState(false)
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'userId',
            render: (val,record, index) => <Link to={`/user/${val}`}>{val}</Link>,
            width: 110,
        },
        {
            title: 'Full Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Telephone',
            dataIndex: 'telephone',
            key: 'telephone',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },

    ]


    const getAllUsers = async ()=>{
        await Queries.get_OOA_unsubscribed_users(pageNo).then(({data})=>{
            if(data && data.length){
            setUsers(data)
            setTotalRecords(data[0].total_count)
            }
        }).catch((err)=>{
            message.error(err.message)
        })
   
    }

    const handelpagechange = (pageno)=>{
      setPageNo(pageno-1)
    }

    const CSV_download = ()=>{
        setCsvLoader(true)
        const csvDate = moment(new Date()).format('DD_MM_YYYY')
        Queries.get_CSV_OOA_unsubscribed_users().then((res)=>{
        fileDownload(res, `OOA_Broadcast_${csvDate}.csv`)
        setCsvLoader(false)
        })
        
    }

    useEffect(() => {
        getAllUsers()
    }, [pageNo])

    const reloadApi = () => {
        getAllUsers()
    }

    return (
        <>
             <div
                style={{
                    justifyContent:'space-between',
                }}>
                <Button
                    icon={<ReloadOutlined />}
                    style={{float:'right',marginRight:'20px'}}
                    onClick={reloadApi}
                    type="primary">
                    Reload
                </Button>
            </div>
            <h1 style={{color:'green',paddingLeft:'10px'}}>Unsubscribed Users {totalrecords}</h1>
                <Table columns={columns} pagination={false} dataSource={users} />
                <br></br>
                <Pagination
                                pageSize={50}
                                total={totalrecords ? parseInt(totalrecords) : 0}
                                onChange={handelpagechange}
                                pageSizeOptions={[50]}
                                showSizeChanger
                                showTotal={(total, range) => (
                                    <Button>
                                        {total && range
                                            ? `Total: ${total}
                                        Users`
                                            : `${0}-${0} of ${0}
                                        Users`}
                                    </Button>
                                )}
                                showNextButton={
                                    false
                                }
                                onNext={()=>{}}
                                className="flex justify-center items-center"
                            />

                <Button
                    style={{margin: '2rem', minHeight: '45px', display: 'flex'}}
                    onClick={CSV_download}
                    loading={csvloader}
                    type="light">
                    <img
                        style={{
                            width: '35px',
                            height: '35px',
                            marginRight: '5px',
                        }}
                        src="/csb.png"
                        alt=""
                    />
                    Download CSV
                </Button>
        </>
    )
}

export default OOA
