import React from 'react'
import moment from 'moment'
import { Col,Row,List,Tag,Typography } from 'antd'
import { Spin } from 'antd'
import { useQuery } from 'react-query'

import Item from './Item' 

import * as messagesQueries from '../../../queries/messages.queries' 
import * as Styled from './counterview.styled'
import { Link } from 'react-router-dom' 

const { Title } = Typography


function MessagesView({ params }){

    const messagesQuery = useQuery(['id' ,params.id],messagesQueries.getMessagesById)
    const messages = messagesQuery.data?.data.data
    console.log(messages);

    if(messagesQuery.isLoading){
        return(
            <Styled.Root>
                <Spin />
            </Styled.Root>
        )
    }

    return(
        <>
            <div className='order'>
                <Col className='order_col'>
                    <List
                    size="small"
                    bordered
                    style={{
                        background:'rgba(0,0,0,0.03)',
                        paddingTop:'1.05rem',
                    }}
                     
                    header={<Title level={5}>Basic Info</Title>}>
                        <Item body={messages.id} title="ID#" />
                        <Item body={<Link to={`/booking/${messages.order_id}`}>{messages.order_id}</Link>} title="Order ID" />
                        <Item body={messages.title} title="Title" />
                        <Item body={<Link to={`/user/${messages.from}`}>{messages.mess_from}</Link>} title="From" />
                        <Item body={<Link to={`/user/${messages.to}`}>{messages.mess_to}</Link>} title="To" />
                        <Item body={messages.content} title="Messages" />
                        <Item
                            body={moment(messages.created_at).format(
                                'YYYY-DD-MM (hh:mm:ss A)',
                            )}
                             title="Created At" 
                        /> 
                    </List>
                </Col>
             </div>
        </>
    )
}

export default MessagesView