import React, {useEffect, useState} from 'react'
import {Col, Table, Button, Pagination, Switch, message} from 'antd'
import axios from 'axios'
import {Link} from 'react-router-dom'
import moment from 'moment'
import fileDownload from 'js-file-download'
function BlockedUser() {
    const [responseData, setResponseData] = useState([])
    const [currentPage, setCurrentPage] = useState(1) // State to track the current page number
    const [pageSize, setPageSize] = useState(10) // State to track the number of items per page
    const [csv, setCsv] = useState(false)
    const token = localStorage.getItem('token')
    useEffect(() => {
        get_blocked_users()
    }, [])

    const get_blocked_users = async () => {
        try {
            const config = {
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json',
                },
            }
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/admin/blocked_users`,
                config,
            )
            setResponseData(response.data.data)
            // console.log('response Data===>', response.data.data)
        } catch (error) {
            console.log(error)
            setResponseData([]) // Set an empty array in case of error
        }
    }

    const unblock_user = async (id)=>{
        try {
            const config = {
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json',
                },
            }
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/admin/blocked_users/${id}`,
                {},
                config,
            )
            get_blocked_users()
            // console.log('response Data===>', response.data.data)
        } catch (error) {
            message.error(error.message)
        }
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: val => `${val}`,
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Telephone',
            dataIndex: 'telephone',
            key: 'telephone',
        },
        {
            title: 'Blocked Until',
            key: 'blocked_until',
            render: val => moment(val.blocked_until).format('YYYY-MM-DD hh:mm A') || 'N/A',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Blocked',
            key: 'id',
            render: (text, record) => (
                <Switch
                    defaultChecked={record.blocked_until} // record.status is the value to set the Switch state
                    onChange={checked => handleUnblockUser(record.id, checked)} // handleUnblockUser receives the id and the new checked state
                />
            ),
            showOnResponse: true,
            showOnDesktop: true,
            width: 500,
        },
    ]
    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page)
        // console.log('pagesize=>', pageSize, page)
        setPageSize(pageSize)
    }
    const handleGoToNext = () => {
        setCurrentPage(prevPage => prevPage + 1)
    }

    const handleUnblockUser = (id)=>{
      unblock_user(id)
    }
    // console.log('current page ==>', currentPage);
    const paginatedDataSource = responseData.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize,
    )
    return (
        <>
            <div
                style={{
                    textAlign: 'right',
                    marginRight: '30px',
                    marginBottom: '5px',
                    color: 'green',
                    fontWeight: 'bold',
                }}>
                Blocked Users : {responseData.length}
            </div>
            <div>
                <Table
                    columns={columns}
                    dataSource={
                        Array.isArray(responseData) ? paginatedDataSource : []
                    }
                    pagination={false}
                    showHeader={true}
                />
                <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={responseData.length}
                    onChange={handlePageChange}
                    showSizeChanger
                    showTotal={(total, range) =>
                        `${range[0]}-${range[1]} of ${total} message`
                    }
                    showNextButton
                    onNext={handleGoToNext}
                    className="my-pagination"
                />
            </div>
        </>
    )
}
export default BlockedUser
