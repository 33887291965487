import React, {useEffect, useState, useRef} from 'react'
import {
    Form,
    Input,
    Button,
    Typography,
    Select,
    DatePicker,
    Checkbox,
    message,
    Spin,
    Space,
    Divider,
} from 'antd'
import {Link, useNavigate} from 'react-router-dom'
import Layout from 'antd/lib/layout/layout'
import * as Styled from './Edit-styled'
import {useQuery} from 'react-query'
import * as userQueries from '../../../queries/user.queries'
import moment from 'moment'
import axios from 'axios'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
    geocodeByPlaceId,
} from 'react-places-autocomplete'
import '../../CreateUser/userStyle.css'
import {ROLE} from 'containers/Config/constants'
import {Col, Row} from 'antd'

const {Title, Paragraph, Text} = Typography
const {TextArea} = Input

function EditUserView({params}) {
    const InterestingOptions = [
        {value: 1, label: 'Delivery'},
        {value: 2, label: 'Home Services/Tasks'},
        {value: 3, label: 'Simple Errands'},
        {value: 4, label: 'Rides'},
        {value: 5, label: 'Moving Junk Removal'},
        {value: 6, label: 'Landscaping Yardwork'},
        {value: 7, label: 'Others'},
    ]

    const discoverGopher = [
        {value: 1, label: 'Referral'},
        {value: 2, label: 'Web Search'},
        {value: 3, label: 'Facebook'},
        {value: 4, label: 'Instagram'},
        {value: 5, label: 'LinkedIn'},
        {value: 6, label: 'Reddit'},
        {value: 8, label: 'Play Store'},
        {value: 9, label: 'App Store'},
        {value: 10, label: 'TV'},
        {value: 11, label: 'Web Search'},
        {value: 7, label: 'Others'},
    ]

    const stripeStates = [
        {value: 1, label: 'AK'},
        {value: 2, label: 'AL'},
        {value: 3, label: 'AR'},
        {value: 4, label: 'AZ'},
        {value: 5, label: 'CA'},
        {value: 6, label: 'CO'},
        {value: 7, label: 'CT'},
        {value: 8, label: 'DC'},
        {value: 9, label: 'DE'},
        {value: 10, label: 'FL'},
        {value: 11, label: 'GA'},
        {value: 12, label: 'HI'},
        {value: 13, label: 'IA'},
        {value: 14, label: 'ID'},
        {value: 15, label: 'IL'},
        {value: 16, label: 'IN'},
        {value: 17, label: 'KS'},
        {value: 18, label: 'KY'},
        {value: 19, label: 'LA'},
        {value: 20, label: 'MA'},
        {value: 21, label: 'MD'},
        {value: 22, label: 'ME'},
        {value: 23, label: 'MI'},
        {value: 24, label: 'MN'},
        {value: 25, label: 'MO'},
        {value: 26, label: 'MS'},
        {value: 27, label: 'MT'},
        {value: 28, label: 'NC'},
        {value: 29, label: 'ND'},
        {value: 30, label: 'NE'},
        {value: 31, label: 'NH'},
        {value: 32, label: 'NJ'},
        {value: 33, label: 'NM'},
        {value: 34, label: 'NV'},
        {value: 35, label: 'NY'},
        {value: 36, label: 'OH'},
        {value: 37, label: 'OK'},
        {value: 38, label: 'OR'},
        {value: 39, label: 'PA'},
        {value: 40, label: 'PR'},
        {value: 41, label: 'RI'},
        {value: 41, label: 'SC'},
        {value: 42, label: 'SD'},
        {value: 43, label: 'TN'},
        {value: 44, label: 'TX'},
        {value: 45, label: 'UT'},
        {value: 46, label: 'VA'},
        {value: 47, label: 'VT'},
        {value: 48, label: 'WA'},
        {value: 49, label: 'WI'},
        {value: 50, label: 'WV'},
        {value: 51, label: 'WY'},
    ]

    const gopher_selecttypeOptions = [
        {value: 1, label: 'Delivery'},
        {value: 2, label: 'Moving'},
        {value: 3, label: 'Junk Removal'},
        {value: 4, label: 'Errand'},
        {value: 5, label: 'Yard Project'},
        {value: 6, label: 'Home Services'},
        {value: 7, label: 'Need a Ride'},
        {value: 8, label: 'Other'},
    ]

    const userQuery = useQuery(['id', params.id], userQueries.getUserById)
    const user = userQuery.data?.data
    const masterInfo = userQuery.data?.data
    const form = Form.useForm()
    const token = localStorage.getItem('token')
    const navigate = useNavigate()

    const [selectedRoleVal, setSelectedRole] = useState([])

    {
        /* --------------------------------------------- First Name, Last Name , Email , Telephone , DOB , passwrod ------------------------------------------------ */
    }
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [dob, setDob] = useState([])
    const [password, setPassword] = useState('')
    const [flag, setflag] = useState(false)
    const [loading, setLoading] = useState(false)
    const [requesting_primarly_others, setRequesting_primarly_others] =
        useState('')
    {
        /* --------------------------------------------- First Name, Last Name , Email , Telephone , DOB , passwrod ------------------------------------------------ */
    }
    {
        /* ----------------------------------------------------------------- Home Address  ---------------------------------------------------------------- */
    }
    const [locationArr, setLocation] = useState({
        address: '',
        line2: '',
        city: '',
        state: '',
        pincode: '',
        latitude: null,
        longitude: null,
    })
    const handleChange = address => {
        setLocation({address: ''})
    }
    const handleSelect = async (address, placeId) => {
        const [place] = await geocodeByPlaceId(placeId)
        await updateLocation(place)
    }
    const updateLocation = async place => {
        let city = ''
        let state = ''
        let country = ''
        let zipcode = ''
        place?.address_components.map(item =>
            item?.types.map(itema => {
                if (itema === 'locality') {
                    city = item?.long_name
                } else if (itema === 'administrative_area_level_1') {
                    state = item?.short_name
                } else if (itema === 'country') {
                    country = item?.short_name
                } else if (itema === 'postal_code') {
                    zipcode = item?.short_name
                }
            }),
        )
        const {long_name: postalCode = ''} =
            place.address_components.find(c =>
                c.types.includes('postal_code'),
            ) || {}
        const newlocationArr = {
            address: place.formatted_address,
            city: city,
            state: state,
            pincode: postalCode,
            latitude: place?.geometry?.location?.lat(),
            longitude: place?.geometry?.location?.lng(),
        }
        setLocation(newlocationArr)
    }
    {
        /* ----------------------------------------------------------------- Home Address  ---------------------------------------------------------------- */
    }
    {
        /* ---------------------------------------------------------------- Persional info --------------------------------------------------------------------- */
    }
    const {Option} = Select
    const [personal, setPersonal] = useState('')
    {
        /* ---------------------------------------------------------------- Persional info --------------------------------------------------------------------- */
    }
    {
        /* --------------------------------------------------------------- How Discover Gopher--------------------------------------------------------------------- */
    }
    const [discoverOther, updateDiscoverOther] = useState(false)
    const [discoverGopherValue, setDiscoverGopher] = useState('')
    const [gopherOther, setGopherOther] = useState('')

    function setDiscoverOther(selectedDiscov) {
        console.log(selectedDiscov)
        let discoverObj = discoverGopher.find(
            obj => obj?.value === selectedDiscov,
        )
        //alert(discoverObj.value);
        if (discoverObj.value == '7') {
            updateDiscoverOther(true)
        } else {
            updateDiscoverOther(false)
            //setGopherOther('');
        }
        console.log(discoverObj)
        console.log(discoverGopherValue)
        setDiscoverGopher(discoverObj)
        var result
        result = selectedDiscov.value === 7 ? true : false

        //updateDiscoverOther(result);
    }

    {
        /* --------------------------------------------------------------- How Discover Gopher--------------------------------------------------------------------- */
    }
    {
        /* --------------------------------------------------------------- Gopher Select Type--------------------------------------------------------------------- */
    }
    // const [gopher_selecttypeValue, setGopher_selecttype] = useState([])
    // const gopher_selecttypetOnchange = (value) => {
    //     setGopher_selecttype(value)
    // }
    {
        /* --------------------------------------------------------------- Gopher Select Type--------------------------------------------------------------------- */
    }
    {
        /* --------------------------------------------------------------- Interesting Reques--------------------------------------------------------------------- */
    }

    const [interestingRequestValues, setInterestingRequest] = useState([
        {value: 1, label: 'Delivery'},
        {value: 2, label: 'Home Services/Tasks'},
    ])
    const [interstingRequetOther, setInterstingRequetOther] = useState(false)
    const interstingRequetOnchange = value => {
        setInterestingRequest(value)
        let findOther = value?.find(obj => obj === 'Others')
        if (findOther) {
            setInterstingRequetOther(true)
        } else {
            setInterstingRequetOther(false)
        }
    }
    {
        /* --------------------------------------------------------------- Interesting Reques--------------------------------------------------------------------- */
    }
    {
        /* ---------------------------------------------------------------- Ride sharing details start ----------------------------------------------------------- */
    }
    function updateRiding() {
        setRiding(!acceptRiding)
    }
    const [acceptRiding, setRiding] = useState(false)
    const [make, setMake] = useState('')
    const [model, setModel] = useState('')
    const [max_passengers, setMax_passengers] = useState(0)
    const [license_plate_number, setLicense_plate_number] = useState(0)
    // const [driver_license, setDriver_license] = useState(0)
    const [checkedDriverLicence, setCheckedDriverLicence] = useState(false)
    const [checkedCarInsurence, setCheckedCarInsurence] = useState(false)
    const [driver_license_number, setDriver_license_number] = useState('')
    const [driver_license_state, setDriver_license_state] = useState('')

    function haveCheckedDriverLicence() {
        setCheckedDriverLicence(!checkedDriverLicence)
    }
    function haveCheckedCarInsurence() {
        setCheckedCarInsurence(!checkedCarInsurence)
    }
    function setStateOther(selectedDiscov) {
        console.log('selected state', selectedDiscov)
        let discoverObj = stripeStates.find(
            obj => obj?.value === selectedDiscov,
        )

        // console.log('discover obje', discoverObj.label)
        setDriver_license_state(discoverObj.label)
    }

    // console.log('driver state data', driver_license_state)

    {
        /* ---------------------------------------------------------------- Ride sharing details start ----------------------------------------------------------- */
    }
    {
        /* --------------------------------------------------------------- Add bussiness start ------------------------------------------------------------------- */
    }
    const [business_name, setBusiness_name] = useState('')
    const [acceptBusiness, setBusinessInfo] = useState(false)
    const [company_title, setCompany_title] = useState('')
    const [license_bounded_insured, setLicense_bounded_insured] =
        useState(false)
    const [about_company, setAbout_company] = useState('')
    function updateBusinessInfo() {
        setBusinessInfo(!acceptBusiness)
    }
    function updateLicense_bounded_insured() {
        setLicense_bounded_insured(!license_bounded_insured)
    }
    const [locationArrBus, setLocationBus] = useState({
        address: '',
        line2: '',
        city: '',
        state: '',
        pincode: '',
        latitude: null,
        longitude: null,
    })
    const handleChangeBussines = address => {
        setLocationBus({address: ''})
    }
    const handleSelectBussine = async (address, placeId) => {
        const [place] = await geocodeByPlaceId(placeId)
        await updateLocationBussines(place)
    }
    const updateLocationBussines = async place => {
        let city = ''
        let state = ''
        let country = ''
        let zipcode = ''
        place?.address_components.map(item =>
            item?.types.map(itema => {
                if (itema === 'locality') {
                    city = item?.long_name
                } else if (itema === 'administrative_area_level_1') {
                    state = item?.short_name
                } else if (itema === 'country') {
                    country = item?.short_name
                } else if (itema === 'postal_code') {
                    zipcode = item?.short_name
                }
            }),
        )
        const {long_name: postalCode = ''} =
            place.address_components.find(c =>
                c.types.includes('postal_code'),
            ) || {}
        const newlocationArr = {
            address: place.formatted_address,
            city: city,
            state: state,
            pincode: postalCode,
            latitude: place?.geometry?.location?.lat(),
            longitude: place?.geometry?.location?.lng(),
        }
        setLocationBus(newlocationArr)
    }
    {
        /* --------------------------------------------------------------- Add bussiness start ------------------------------------------------------------------- */
    }

    useEffect(() => {
        if (user) {
            // checking for basic info
            setFirstName(user?.first_name)
            setLastName(user?.last_name)
            setEmail(user?.email)
            setDob(user?.date_of_birth)
            setPhone(user?.telephone)
            setRequesting_primarly_others(user?.requesting_primarly_others)

            setflag(true)
            // checking for Home address is having
            const newlocationArr = {
                address: user?.address?.line1,
                line2: user?.address?.line2,
                city: user?.address?.city,
                state: user?.address?.state,
                pincode: user?.address?.zip_code,
                latitude: user?.address?.latitude,
                longitude: user?.address?.longitude,
            }
            setLocation(newlocationArr)

            // Adding bussiness details started -----------------------
            const newlocationArrBussiness = {
                address: user?.business_address?.line1,
                line2: user?.business_address?.line2,
                city: user?.business_address?.city,
                state: user?.business_address?.state,
                pincode: user?.business_address?.zip_code,
                latitude: user?.business_address?.latitude,
                longitude: user?.business_address?.longitude,
            }
            setLocationBus(newlocationArrBussiness)
            // Adding bussiness details ending -----------------------

            // checking edit user role
            let userArray = []
            if (user?.is_admin) {
                userArray.push(ROLE[0])
            }
            if (user?.is_requester) {
                userArray.push(ROLE[2])
            }
            if (user?.is_gopher) {
                userArray.push(ROLE[1])
            }
            setSelectedRole(userArray)

            // Ride related things -------------------------------------------------------start
            if (user?.user_info?.ride_sharing) {
                setRiding(true)
            } else {
                setRiding(false)
            }
            setMake(user?.user_info?.make)
            setModel(user?.user_info?.model)
            setMax_passengers(user?.user_info?.max_passengers)
            setLicense_plate_number(user?.user_info?.license_plate_number)
            // setDriver_license(user?.user_info?.driver_license)
            setCheckedDriverLicence(user?.user_info?.driver_license)
            setCheckedCarInsurence(user?.user_info?.car_insurance)

            //add
            setDriver_license_number(user?.user_info?.driver_license_number)
            setDriver_license_state(user?.user_info?.driver_license_state)

            setPersonal(user?.user_info?.personal_info)
            let valueFilterDiscoverGopher = discoverGopher.find(
                obj => obj?.label == user?.discover_gopher,
            )
            setDiscoverGopher(valueFilterDiscoverGopher)
            setPersonal(user?.user_info?.personal_info)
            if (user?.requesting_primarly) {
                let requesting_primarlyArray =
                    user?.requesting_primarly.split(',')
                let checkOther = requesting_primarlyArray.includes('Others')

                if (checkOther) {
                    setInterstingRequetOther(true)
                } else {
                    setInterstingRequetOther(false)
                }
                setInterestingRequest(requesting_primarlyArray)
            } else {
                setInterestingRequest([])
            }

            // if (user?.gopher_selecttype) {
            //     let gopher_selecttypeArray = user?.gopher_selecttype.split(",");
            //     setGopher_selecttype(gopher_selecttypeArray)
            // } else {
            //     setGopher_selecttype([])
            // }
            //updateDiscoverOther(user?.discover_gopher_others);
            if (user?.discover_gopher == 'Others') {
                updateDiscoverOther(true)
                setGopherOther(user?.discover_gopher_others)
                //updateDiscoverOther(user?.discover_gopher_others);
            } else {
                updateDiscoverOther(false)
                /// updateDiscoverOther(user?.discover_gopher_others);
            }

            setBusinessInfo(user?.user_info?.add_business)
            setBusiness_name(user?.user_info?.business_name)
            setCompany_title(user?.user_info?.company_title)
            setLicense_bounded_insured(user?.user_info?.license_bounded_insured)
            setAbout_company(user?.user_info?.about_company)
        }
    }, [user])

    const dateFormat = 'YYYY-MM-DD'

    if (flag === false) {
        return (
            <Styled.Root>
                <Spin />
            </Styled.Root>
        )
    }

    // persional info change
    const personalInfo = event => {
        setPersonal(event.target.value)
    }
    function updateUser(values) {
        // Update for normal user ----------------
        if (selectedRoleVal?.includes('GOPHER')) {
            let sendObj = masterInfo
            const data = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phoneNo: values.telephone,
                // dob: values.dateOfBirth,
            }
            sendObj['user_info'] = {}
            // Out side the object section
            sendObj['firstName'] = values?.firstName
            sendObj['lastName'] = values?.lastName
            sendObj['email'] = values?.email
            sendObj['phoneNo'] = values?.telephone
            //sendObj['date_of_birth'] = values?.dateOfBirth
            sendObj['dob'] = values?.dateOfBirth

            if (values?.personal) {
                sendObj['user_info']['personal_info'] = values?.personal
            }
            if (discoverGopherValue?.label) {
                sendObj['discover_gopher'] = discoverGopherValue?.label
            }
            //  if(gopher_selecttypeValue && gopher_selecttypeValue?.length>0){
            //     let listOfGophor=gopher_selecttypeValue?.toString()
            //     sendObj['gopher_selecttype']=listOfGophor
            // }
            if (interestingRequestValues?.length > 0) {
                let listOfinterestingRequestValues =
                    interestingRequestValues?.toString()
                sendObj['requesting_primarly'] = listOfinterestingRequestValues
            }

            if (sendObj['requesting_primarly'].indexOf('Others') > -1) {
                sendObj['other_interesting_request_primarly'] =
                    values?.other_interesting_request_primarly
            } else {
                sendObj['other_interesting_request_primarly'] = ''
            }

            // ------------------------------- Ride sharing --------------------------------------------
            if (acceptRiding == false || acceptRiding == true) {
                sendObj['user_info']['ride_sharing'] = acceptRiding
            }
            if (values && values?.make) {
                sendObj['user_info']['make'] = values?.make
            }
            if (values && values?.model) {
                sendObj['user_info']['model'] = values?.model
            }

            if (values && values?.max_passengers) {
                sendObj['user_info']['max_passengers'] = values?.max_passengers
            }
            if (values && values?.license_plate_number) {
                sendObj['user_info']['license_plate_number'] =
                    values?.license_plate_number
            }

            //Add
            if (values && values?.driver_license_number) {
                sendObj['user_info']['driver_license_number'] =
                    values?.driver_license_number
            }

            // if (values && values?.driver_license_state) {
            //     console.log('driver lincesn staate data:===>', values)
            //     sendObj['user_info']['driver_license_state'] =
            //         values?.driver_license_state
            // }

            // if (discoverGopherValue?.label) {
            //     sendObj['discover_gopher'] = discoverGopherValue?.label
            // }

            // console.log('Driver lincense state label===>', driver_license_state)

            if (driver_license_state) {
                sendObj['user_info']['driver_license_state'] =
                    driver_license_state
            }

            sendObj['user_info']['driver_license'] = checkedDriverLicence
            sendObj['user_info']['car_insurance'] = checkedCarInsurence
            // -------------------------------- Ride sharing ---------------------------------------------
            // -------------------------------- Add Business --------------------------------------------
            sendObj['user_info']['add_business'] = acceptBusiness
            if (values && values?.company_title) {
                sendObj['user_info']['business_name'] = values?.business_name
            }
            if (values && values?.company_title) {
                sendObj['user_info']['company_title'] = values?.company_title
            }
            if (values && values?.about_company) {
                sendObj['user_info']['about_company'] = values?.about_company
            }
            sendObj['user_info']['license_bounded_insured'] =
                license_bounded_insured
            // -------------------------------- Add Business --------------------------------------------
            // -------------------------------- Add Address --------------------------------------------
            if (locationArr?.address) {
                sendObj['address']['line1'] = locationArr?.address
            }
            if (locationArr?.line2) {
                sendObj['address']['line2'] = locationArr?.line2
            }
            if (locationArr?.city) {
                sendObj['address']['city'] = locationArr?.city
            }
            if (locationArr?.state) {
                sendObj['address']['state'] = locationArr?.state
            }
            if (locationArr?.pincode) {
                sendObj['address']['zip_code'] = locationArr?.pincode
            }
            if (locationArr?.latitude) {
                sendObj['address']['latitude'] = locationArr?.latitude
            }
            if (locationArr?.longitude) {
                sendObj['address']['longitude'] = locationArr?.longitude
            }
            // -------------------------------- Add Business Address --------------------------------------------
            if (locationArrBus?.address) {
                sendObj['business_address']['line1'] = locationArrBus?.address
            }
            if (locationArrBus?.line2) {
                sendObj['business_address']['line2'] = locationArrBus?.line2
            }
            if (locationArrBus?.city) {
                sendObj['business_address']['city'] = locationArrBus?.city
            }
            if (locationArrBus?.state) {
                sendObj['business_address']['state'] = locationArrBus?.state
            }
            if (locationArrBus?.pincode) {
                sendObj['business_address']['zip_code'] =
                    locationArrBus?.pincode
            }
            if (locationArrBus?.latitude) {
                sendObj['business_address']['latitude'] =
                    locationArrBus?.latitude
            }
            if (locationArrBus?.longitude) {
                sendObj['business_address']['longitude'] =
                    locationArrBus?.longitude
            }

            //sendObj['other_interesting_request'] =values?.other_interesting_request
            if (sendObj['discover_gopher'] == 'Others') {
                sendObj['other_interesting_request'] =
                    values?.other_interesting_request
            } else {
                sendObj['other_interesting_request'] = ''
            }

            console.log('***', locationArr.address)

            axios
                .put(
                    `${process.env.REACT_APP_BASE_URL}/admin/user/${params.id}`,
                    sendObj,
                    {
                        headers: {
                            Authorization: token,
                        },
                    },
                )
                .then(res => {
                    if (res?.data?.success == true) {
                        message.success(res.data.message)
                        navigate(`/user/${params.id}`)
                    } else {
                        message.error(res.data.message)
                    }
                    setLoading(false)
                })
                .catch(error => {
                    message.error(error?.response?.data?.message)
                    setLoading(false)
                })
            setLoading(false)
        } else if (selectedRoleVal?.includes('REQUESTER')) {
            // If Role is Admin --------------------------------------- Start ---------------------
            setLoading(true)
            let data = masterInfo
            data['firstName'] = values?.firstName
            data['lastName'] = values?.lastName
            data['email'] = values?.email
            data['phoneNo'] = values?.telephone
            data['dob'] = values?.dateOfBirth
            data['is_requester'] = true

            if (discoverGopherValue?.label) {
                data['discover_gopher'] = discoverGopherValue?.label
            }

            if (data['discover_gopher'] == 'Others') {
                data['other_interesting_request'] =
                    values?.other_interesting_request
            } else {
                data['other_interesting_request'] = ''
            }

            if (interestingRequestValues?.length > 0) {
                let listOfinterestingRequestValues =
                    interestingRequestValues?.toString()
                data['requesting_primarly'] = listOfinterestingRequestValues
            }

            if (data['requesting_primarly'].indexOf('Others') > -1) {
                data['other_interesting_request_primarly'] =
                    values?.other_interesting_request_primarly
            } else {
                data['other_interesting_request_primarly'] = ''
            }

            // data['other_interesting_request_primarly']= values?.other_interesting_request_primarly

            if (locationArr?.address) {
                data['address']['line1'] = locationArr?.address
            }
            if (locationArr?.line2) {
                data['address']['line2'] = locationArr?.line2
            }
            if (locationArr?.city) {
                data['address']['city'] = locationArr?.city
            }
            if (locationArr?.state) {
                data['address']['state'] = locationArr?.state
            }
            if (locationArr?.pincode) {
                data['address']['zip_code'] = locationArr?.pincode
            }
            if (locationArr?.latitude) {
                data['address']['latitude'] = locationArr?.latitude
            }
            if (locationArr?.longitude) {
                data['address']['longitude'] = locationArr?.longitude
            }

            axios
                .put(
                    `${process.env.REACT_APP_BASE_URL}/admin/user/${params.id}`,
                    data,
                    {
                        headers: {
                            Authorization: token,
                        },
                    },
                )
                .then(res => {
                    if (res?.data?.success == true) {
                        message.success(res.data.message)
                        navigate(`/user/${params.id}`)
                    } else {
                        message.error(res.data.message)
                    }
                    setLoading(false)
                })
                .catch(error => {
                    message.error(error?.response?.data?.message)
                    setLoading(false)
                })
            setLoading(false)
            // If Role is Admin --------------------------------------- Start ---------------------
        } else {
            // If Role is Admin --------------------------------------- Start ---------------------
            setLoading(true)
            const data = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phoneNo: values.telephone,
                dob: values.dateOfBirth,
                password: values.password,
                address: values.address,
                is_admin: true,
            }

            axios
                .put(
                    `${process.env.REACT_APP_BASE_URL}/admin/user/${params.id}`,
                    data,
                    {
                        headers: {
                            Authorization: token,
                        },
                    },
                )
                .then(res => {
                    if (res?.data?.success == true) {
                        message.success(res.data.message)
                        navigate(`/user/${params.id}`)
                    } else {
                        message.error(res.data.message)
                    }
                    setLoading(false)
                })
                .catch(error => {
                    message.error(error?.response?.data?.message)
                    setLoading(false)
                })
            setLoading(false)
            // If Role is Admin --------------------------------------- Start ---------------------
        }
    }
    // console.log(locationArr.city)

    //states data

    return (
        <Styled.Root>
            <Styled.CreateForm
                name="auth"
                form={form[0]}
                initialValues={{remember: true}}
                onFinish={updateUser}
                autoComplete="off">
                <Title level={2} style={{marginBottom: 20}}>
                    Edit User
                </Title>
                <Form.Item
                    label="Role"
                    name="firstName"
                    labelAlign="left"
                    labelCol={{xs: 5}}>
                    {selectedRoleVal?.map((obj, index) => {
                        return (
                            <>
                                <Text strong>{obj}</Text>
                                {selectedRoleVal.length > 0 ? (
                                    <Text strong> , </Text>
                                ) : (
                                    ''
                                )}
                            </>
                        )
                    })}
                </Form.Item>
                {/* --------------------------------------------- First Name, Last Name , Email , Telephone , DOB , passwrod ------------------------------------------------ */}
                <Form.Item
                    label="First Name"
                    name="firstName"
                    initialValue={firstName}
                    labelAlign="left"
                    labelCol={{xs: 5}}
                    rules={[
                        {
                            required: true,
                            message: 'Please input your First name!',
                        },
                    ]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Last Name"
                    name="lastName"
                    initialValue={lastName}
                    labelAlign="left"
                    labelCol={{xs: 5}}
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Last name!',
                        },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    initialValue={email}
                    labelCol={{xs: 5}}
                    labelAlign="left"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                            type: 'email',
                        },
                    ]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Telephone"
                    name="telephone"
                    initialValue={phone}
                    labelAlign="left"
                    labelCol={{xs: 5}}
                    rules={[
                        {
                            required: true,
                            message: 'Please input a valid telephone!',
                        },
                    ]}>
                    <Input style={{width: '100%'}} minLength={10} />
                </Form.Item>

                <Form.Item
                    style={{paddingTop: 10}}
                    label="Date of Birth"
                    name="dateOfBirth"
                    // initialValue={moment(`${dob}`, dateFormat)}
                    initialValue={moment.utc(`${dob}`, dateFormat)}
                    labelAlign="left"
                    labelCol={{xs: 5}}
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Date  of Birth!',
                        },
                    ]}>
                    <DatePicker
                        //defaultValue={moment(`${dob}`, dateFormat)}
                        //format={`${dateFormat} HH:mm:ss`}
                        format={dateFormat}
                        //onChange={setDob}
                    />
                </Form.Item>
                {selectedRoleVal?.includes('ADMIN') ? (
                    <Form.Item
                        label="Add New Password"
                        name="password"
                        labelAlign="left"
                        labelCol={{xs: 5}}
                        rules={[
                            {
                                //required: true,
                                message: 'Please input your password!',
                                type: 'string',
                            },
                        ]}>
                        <Input.Password />
                    </Form.Item>
                ) : (
                    ''
                )}
                {selectedRoleVal?.includes('ADMIN') ? (
                    <Form.Item
                        label="Confirm New Password"
                        name="conf-password"
                        labelAlign="left"
                        labelCol={{xs: 5}}
                        rules={[
                            {
                                message: 'Please input your confirm password!',
                                type: 'string',
                            },
                        ]}>
                        <Input.Password />
                    </Form.Item>
                ) : (
                    ''
                )}
                {/* --------------------------------------------- First Name, Last Name , Email , Telephone , DOB , passwrod ------------------------------------------------ */}
                {/* ----------------------------------------------------------------- Home Address  ---------------------------------------------------------------- */}
                {!selectedRoleVal?.includes('ADMIN') ? (
                    <Form.Item
                        style={{paddingTop: 10, marginBottom: 10}}
                        label="Home Address"
                        name="address"
                        labelAlign="left"
                        labelCol={{xs: 5}}>
                        <PlacesAutocomplete
                            value={locationArr.address}
                            onChange={handleChange}
                            onSelect={handleSelect}
                            className="searchBox">
                            {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                            }) => (
                                <div>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Search Places ...',
                                            className: 'location-search-input',
                                        })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item'
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? {
                                                      backgroundColor:
                                                          '#bcc6cf',
                                                      cursor: 'pointer',
                                                  }
                                                : {
                                                      backgroundColor:
                                                          '#ffffff',
                                                      cursor: 'pointer',
                                                  }
                                            return (
                                                <div
                                                    className="input-suggestion"
                                                    {...getSuggestionItemProps(
                                                        suggestion,
                                                        {
                                                            className,
                                                            style,
                                                        },
                                                    )}>
                                                    <span>
                                                        {suggestion.description}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </Form.Item>
                ) : (
                    ''
                )}
                {!selectedRoleVal?.includes('ADMIN') ? (
                    <Form.Item
                        style={{paddingTop: 10}}
                        label="."
                        name="home_address"
                        labelAlign="left"
                        labelCol={{xs: 5}}>
                        <Paragraph style={{marginBottom: '0px'}}>
                            Line 1{' '}
                        </Paragraph>
                        <Input
                            type="text"
                            value={locationArr?.address}
                            onChange={e =>
                                setLocation({
                                    ...locationArr,
                                    address: e.target.value,
                                })
                            }
                        />
                        <Paragraph style={{marginBottom: '0px'}}>
                            Line 2{' '}
                        </Paragraph>
                        <Input type="text" className="form-control" />
                        <Paragraph style={{marginBottom: '0px'}}>
                            State{' '}
                        </Paragraph>
                        <Input
                            type="text"
                            className="form-control"
                            value={locationArr?.state}
                            onChange={e =>
                                setLocation({
                                    ...locationArr,
                                    state: e.target.value,
                                })
                            }
                        />
                        <Paragraph style={{marginBottom: '0px'}}>
                            City
                        </Paragraph>
                        <Input
                            type="text"
                            className="form-control"
                            value={locationArr?.city}
                            onChange={e =>
                                setLocation({
                                    ...locationArr,
                                    city: e.target.value,
                                })
                            }
                        />
                        <Paragraph style={{marginBottom: '0px'}}>
                            Pincode
                        </Paragraph>
                        <Input
                            type="text"
                            className="form-control"
                            value={locationArr?.pincode}
                            onChange={e =>
                                setLocation({
                                    ...locationArr,
                                    pincode: e.target.value,
                                })
                            }
                        />
                        <Row className="rowCenter">
                            {locationArr?.latitude ? (
                                <Col span={12}>
                                    <span className="latlogSet">latitude</span>
                                    {locationArr?.latitude}
                                </Col>
                            ) : (
                                ''
                            )}
                            {locationArr?.longitude ? (
                                <Col span={12}>
                                    <span className="latlogSet">longitude</span>
                                    {locationArr?.longitude}
                                </Col>
                            ) : (
                                ''
                            )}
                        </Row>
                    </Form.Item>
                ) : (
                    ''
                )}

                {/* ----------------------------------------------------------------- Home Address  ---------------------------------------------------------------- */}
                {/* ---------------------------------------------------------------- Persional info --------------------------------------------------------------------- */}
                {selectedRoleVal?.includes('GOPHER') ? (
                    <Form.Item
                        style={{paddingTop: 10}}
                        label="Personal Info"
                        name="personal"
                        initialValue={personal}
                        labelAlign="left"
                        labelCol={{xs: 6}}
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please input personal info!',
                        //     },
                        // ]}
                    >
                        <TextArea autoSize={{minRows: 2, maxRows: 6}} />
                        {/* <Paragraph style={{ marginBottom: '0px' }}>What would you like your neighbors to know about you as a Gopher</Paragraph > */}
                    </Form.Item>
                ) : (
                    ''
                )}

                {/* ---------------------------------------------------------------- Persional info --------------------------------------------------------------------- */}
                {/* --------------------------------------------------------------- How Discover Gopher--------------------------------------------------------------------- */}
                {selectedRoleVal?.includes('GOPHER') ? (
                    <>
                        <Form.Item
                            label="How Discover Gopher"
                            name="discoverGopherValue"
                            labelAlign="left"
                            initialValue={discoverGopherValue}
                            labelCol={{xs: 6}}
                            rules={[
                                {
                                    required: false,
                                    message:
                                        'Please input how discover gopher!',
                                },
                            ]}>
                            <Select
                                options={discoverGopher}
                                onChange={setDiscoverOther}
                                defaultValue={discoverGopherValue}></Select>
                            <></>
                            {/* {discoverOther && (
                            <input />
                        )} */}{' '}
                        </Form.Item>
                        {discoverOther ? (
                            <Form.Item
                                label="Other Interesting Request"
                                name="other_interesting_request"
                                initialValue={gopherOther}
                                labelAlign="left"
                                labelCol={{xs: 6}}
                                style={{marginTop: 0}}>
                                <Input type="text" className="form-control" />
                            </Form.Item>
                        ) : (
                            ''
                        )}

                        {/* --------------------------------------------------------------- How Discover Gopher--------------------------------------------------------------------- */}
                        {/* --------------------------------------------------------------- Gopher Select Type--------------------------------------------------------------------- */}
                        {/* <Form.Item
                        label='Gopher Select Type'
                        name='gopher_selecttypeValue'
                        labelAlign='left'
                        labelCol={{ xs: 6 }}
                        initialValue={gopher_selecttypeValue}
                        // style={{ marginBottom:  }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input gopher select request!',
                            }
                        ]}>
                        <Select
                            mode="multiple"
                            style={{
                                width: '100%',
                            }}
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider
                                        style={{
                                            margin: '8px 0',
                                        }}
                                    />
                                    <Space
                                        style={{
                                            padding: '0 8px 4px',
                                        }}
                                    >
                                    </Space>
                                </>
                            )}
                            // defaultValue={gopher_selecttypeValue}
                            onChange={gopher_selecttypetOnchange}
                        >
                            {gopher_selecttypeOptions.map((item) => (
                                <Option key={item?.value} value={item?.label}>{item?.label}</Option>
                            ))}
                        </Select>
                    </Form.Item> */}
                        {/* --------------------------------------------------------------- Interesting Reques--------------------------------------------------------------------- */}
                        {/* --------------------------------------------------------------- Interesting Reques--------------------------------------------------------------------- */}
                        <Form.Item
                            label="Interesting Request"
                            name="interestingRequestValues"
                            labelAlign="left"
                            labelCol={{xs: 6}}
                            initialValue={interestingRequestValues}
                            style={{marginBottom: 1}}
                            rules={[
                                {
                                    required: false,
                                    message:
                                        'Please input interesting request!',
                                },
                            ]}>
                            <Select
                                mode="multiple"
                                style={{
                                    width: '100%',
                                }}
                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider
                                            style={{
                                                margin: '8px 0',
                                            }}
                                        />
                                        <Space
                                            style={{
                                                padding: '0 8px 4px',
                                            }}></Space>
                                    </>
                                )}
                                defaultValue={interestingRequestValues}
                                onChange={interstingRequetOnchange}>
                                {InterestingOptions.map(item => (
                                    <Option
                                        key={item?.value}
                                        value={item?.label}>
                                        {item?.label}
                                    </Option>
                                ))}
                            </Select>
                            <Paragraph style={{marginBottom: '1px'}}>
                                What request are you most intersting in seeing ?
                            </Paragraph>
                        </Form.Item>
                        {interstingRequetOther ? (
                            <Form.Item
                                label="Other Interesting Request"
                                name="other_interesting_request_primarly"
                                initialValue={requesting_primarly_others}
                                labelAlign="left"
                                labelCol={{xs: 6}}
                                style={{marginTop: 0}}>
                                <Input type="text" className="form-control" />
                            </Form.Item>
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    ''
                )}
                {/* --------------------------------------------------------------- Interesting Reques--------------------------------------------------------------------- */}
                <br></br>
                {/* ---------------------------------------------------------------- Ride sharing details start ----------------------------------------------------------- */}
                {selectedRoleVal?.includes('GOPHER') ? (
                    <Checkbox
                        checked={acceptRiding}
                        onChange={updateRiding}
                        style={{paddingBottom: '20px'}}>
                        I AM INTERRESTED IN RIDE SHARING
                    </Checkbox>
                ) : (
                    ''
                )}
                {acceptRiding && (
                    <div className="externalOption">
                        <Paragraph
                            style={{marginBottom: '0px', fontWeight: '500'}}>
                            Car/Driver Info
                        </Paragraph>
                        <Form.Item
                            style={{paddingTop: 10}}
                            label="Make"
                            name="make"
                            labelAlign="left"
                            initialValue={make}
                            labelCol={{xs: 5}}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Make!',
                                },
                            ]}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            style={{paddingTop: 0}}
                            label="Model"
                            name="model"
                            labelAlign="left"
                            labelCol={{xs: 5}}
                            initialValue={model}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Model!',
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            style={{paddingTop: 0}}
                            label="Max passenger"
                            name="max_passengers"
                            labelAlign="left"
                            initialValue={max_passengers}
                            labelCol={{xs: 5}}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Max passenger!',
                                },
                            ]}>
                            <Input
                                type="number"
                                defaultValue={max_passengers}
                            />
                        </Form.Item>
                        <Form.Item
                            style={{paddingTop: 0}}
                            label="License plate number"
                            name="license_plate_number"
                            labelAlign="left"
                            initialValue={license_plate_number}
                            labelCol={{xs: 6}}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please input License plate number!',
                                },
                            ]}>
                            <Input defaultValue={license_plate_number} />
                        </Form.Item>
                        <Form.Item
                            style={{paddingTop: 0, marginBottom: '2px'}}
                            label="Have valid Driver License"
                            name="checkedDriverLicence"
                            initialValue={checkedDriverLicence}
                            labelAlign="left"
                            labelCol={{xs: 7}}>
                            <Checkbox
                                checked={checkedDriverLicence}
                                onChange={haveCheckedDriverLicence}
                            />
                        </Form.Item>
                        <Form.Item
                            style={{paddingTop: 0}}
                            label="Have current car insurence"
                            name="checkedCarInsurence"
                            initialValue={checkedCarInsurence}
                            labelAlign="left"
                            labelCol={{xs: 7}}>
                            <Checkbox
                                checked={checkedCarInsurence}
                                onChange={haveCheckedCarInsurence}
                            />
                        </Form.Item>

                        {/*  Rahul  */}
                        <Form.Item
                            style={{paddingTop: 0}}
                            label="Driver license number"
                            name="driver_license_number"
                            labelAlign="left"
                            initialValue={driver_license_number}
                            labelCol={{xs: 6}}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please input driver license  number!',
                                },
                            ]}>
                            <Input defaultValue={driver_license_number} />
                        </Form.Item>

                        {/* <Form.Item
                            style={{paddingTop: 0}}
                            label="Driver license state"
                            name="driver_license_state"
                            labelAlign="left"
                            initialValue={driver_license_state}
                            labelCol={{xs: 6}}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please input driver license  state!',
                                },
                            ]}>
                            <Input defaultValue={driver_license_state} />
                        </Form.Item> */}

                        <Form.Item
                            style={{paddingTop: 0}}
                            label="Driver license state"
                            name="driver_license_state"
                            labelAlign="left"
                            initialValue={driver_license_state}
                            labelCol={{xs: 6}}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please input driver license  state!',
                                },
                            ]}>
                            <Select
                                options={stripeStates}
                                // onChange={setDiscoverOther}
                                onChange={setStateOther}
                                defaultValue={driver_license_state}></Select>
                            <></>
                        </Form.Item>

                        <Form.Item
                            style={{paddingTop: 0, marginBottom: '2px'}}
                            label="Are you licensed, Bonded and/or insured"
                            name="license_bounded_insured"
                            labelAlign="left"
                            initialValue={license_bounded_insured}
                            labelCol={{xs: 10}}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please input Are you licensed, Bonded and/or insured!',
                            //     },
                            // ]}
                        >
                            <Checkbox
                                checked={license_bounded_insured}
                                onChange={updateLicense_bounded_insured}
                            />
                        </Form.Item>
                    </div>
                )}
                {/* ---------------------------------------------------------------- Ride sharing details end ----------------------------------------------------------- */}
                <br></br>
                {/* ---------------------------------------------------------------- Requester discover details end ----------------------------------------------------------- */}
                {selectedRoleVal?.includes('REQUESTER') ? (
                    <>
                        <Form.Item
                            label="How Discover Gopher"
                            name="discoverGopherValue"
                            labelAlign="left"
                            initialValue={discoverGopherValue}
                            labelCol={{xs: 6}}
                            rules={[
                                {
                                    required: false,
                                    message:
                                        'Please input how discover gopher!',
                                },
                            ]}>
                            <Select
                                options={discoverGopher}
                                onChange={setDiscoverOther}
                                defaultValue={discoverGopherValue}></Select>
                            <></>
                            {/* {discoverOther && ( 
                            <input />
                        )} */}
                        </Form.Item>
                        {discoverOther ? (
                            <Form.Item
                                label="Other Interesting Request"
                                name="other_interesting_request"
                                initialValue={gopherOther}
                                labelAlign="left"
                                labelCol={{xs: 6}}
                                style={{marginTop: 0}}>
                                <Input type="text" className="form-control" />
                            </Form.Item>
                        ) : (
                            ''
                        )}
                        {/* --------------------------------------------------------------- How Discover Gopher--------------------------------------------------------------------- */}
                        {/* --------------------------------------------------------------- Gopher Select Type--------------------------------------------------------------------- */}
                        {/* <Form.Item
                        label='Gopher Select Type'
                        name='gopher_selecttypeValue'
                        labelAlign='left'
                        labelCol={{ xs: 6 }}
                        initialValue={gopher_selecttypeValue}
                        // style={{ marginBottom:  }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input gopher select request!',
                            }
                        ]}>
                        <Select
                            mode="multiple"
                            style={{
                                width: '100%',
                            }}
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider
                                        style={{
                                            margin: '8px 0',
                                        }}
                                    />
                                    <Space
                                        style={{
                                            padding: '0 8px 4px',
                                        }}
                                    >
                                    </Space>
                                </>
                            )}
                            // defaultValue={gopher_selecttypeValue}
                            onChange={gopher_selecttypetOnchange}
                        >
                            {gopher_selecttypeOptions.map((item) => (
                                <Option key={item?.value} value={item?.label}>{item?.label}</Option>
                            ))}
                        </Select>
                    </Form.Item> */}
                        {/* --------------------------------------------------------------- Interesting Reques--------------------------------------------------------------------- */}
                        {/* --------------------------------------------------------------- Interesting Reques--------------------------------------------------------------------- */}
                        <Form.Item
                            label="Interesting Request"
                            name="interestingRequestValues"
                            labelAlign="left"
                            labelCol={{xs: 6}}
                            initialValue={interestingRequestValues}
                            style={{marginBottom: 1}}
                            rules={[
                                {
                                    required: false,
                                    message:
                                        'Please input interesting request!',
                                },
                            ]}>
                            <Select
                                mode="multiple"
                                style={{
                                    width: '100%',
                                }}
                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider
                                            style={{
                                                margin: '8px 0',
                                            }}
                                        />
                                        <Space
                                            style={{
                                                padding: '0 8px 4px',
                                            }}></Space>
                                    </>
                                )}
                                defaultValue={interestingRequestValues}
                                onChange={interstingRequetOnchange}>
                                {InterestingOptions.map(item => (
                                    <Option
                                        key={item?.value}
                                        value={item?.label}>
                                        {item?.label}
                                    </Option>
                                ))}
                            </Select>
                            <Paragraph style={{marginBottom: '1px'}}>
                                What request are you most intersting in seeing ?
                            </Paragraph>
                        </Form.Item>
                        {interstingRequetOther ? (
                            <Form.Item
                                label="Other Interesting Request"
                                name="other_interesting_request_primarly"
                                initialValue={requesting_primarly_others}
                                labelAlign="left"
                                labelCol={{xs: 6}}
                                style={{marginTop: 0}}>
                                <Input type="text" className="form-control" />
                            </Form.Item>
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    ''
                )}

                <br></br>

                {/* --------------------------------------------------------------- Add bussiness start ------------------------------------------------------------------- */}
                {selectedRoleVal?.includes('GOPHER') ? (
                    <Checkbox
                        checked={acceptBusiness}
                        onChange={updateBusinessInfo}
                        style={{paddingBottom: '20px'}}>
                        Add ME AS BUSINESS
                    </Checkbox>
                ) : (
                    ''
                )}
                {acceptBusiness && (
                    <div className="externalOptionOpt">
                        <Paragraph
                            style={{marginBottom: '0px', fontWeight: '500'}}>
                            Business Info
                        </Paragraph>
                        <Form.Item
                            style={{paddingTop: 10}}
                            label="Business Name"
                            name="business_name"
                            initialValue={business_name}
                            labelAlign="left"
                            labelCol={{xs: 5}}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Business Name!',
                                },
                            ]}>
                            <Input defaultValue={business_name} />
                        </Form.Item>

                        <Form.Item
                            style={{paddingTop: 0}}
                            label="Company title"
                            name="company_title"
                            initialValue={company_title}
                            labelAlign="left"
                            labelCol={{xs: 5}}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Company title!',
                                },
                            ]}>
                            <Input defaultValue={company_title} />
                        </Form.Item>

                        <Form.Item
                            style={{paddingTop: 0}}
                            label="Business Address"
                            name="business_address"
                            labelAlign="left"
                            labelCol={{xs: 5}}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please input Business Address!',
                            //     },
                            // ]}
                        >
                            <PlacesAutocomplete
                                value={locationArrBus.address}
                                onChange={handleChangeBussines}
                                onSelect={handleSelectBussine}
                                className="searchBox">
                                {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder:
                                                    'Search Places ...',
                                                className:
                                                    'location-search-input',
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className =
                                                    suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item'
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? {
                                                          backgroundColor:
                                                              '#bcc6cf',
                                                          cursor: 'pointer',
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              '#ffffff',
                                                          cursor: 'pointer',
                                                      }
                                                return (
                                                    <div
                                                        className="input-suggestion"
                                                        {...getSuggestionItemProps(
                                                            suggestion,
                                                            {
                                                                className,
                                                                style,
                                                            },
                                                        )}>
                                                        <span>
                                                            {
                                                                suggestion.description
                                                            }
                                                        </span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </Form.Item>

                        <Form.Item
                            style={{paddingTop: 0}}
                            label="."
                            name="bus_address"
                            labelAlign="left"
                            labelCol={{xs: 5}}>
                            {' '}
                            setLocationBus
                            <Paragraph style={{marginBottom: '0px'}}>
                                Line 1{' '}
                            </Paragraph>
                            <Input
                                type="text"
                                name="city"
                                className="form-control"
                                value={locationArrBus?.address}
                                onChange={e =>
                                    setLocationBus({
                                        ...locationArrBus,
                                        address: e.target.value,
                                    })
                                }
                            />
                            <Paragraph style={{marginBottom: '0px'}}>
                                Line 2{' '}
                            </Paragraph>
                            <Input
                                type="text"
                                name="city"
                                className="form-control"
                            />
                            <Paragraph style={{marginBottom: '0px'}}>
                                State
                            </Paragraph>
                            <Input
                                type="text"
                                name="city"
                                className="form-control"
                                value={locationArrBus?.state}
                                onChange={e =>
                                    setLocationBus({
                                        ...locationArrBus,
                                        state: e.target.value,
                                    })
                                }
                            />
                            <Paragraph style={{marginBottom: '0px'}}>
                                City
                            </Paragraph>
                            <Input
                                type="text"
                                name="city"
                                className="form-control"
                                value={locationArrBus?.city}
                                onChange={e =>
                                    setLocationBus({
                                        ...locationArrBus,
                                        city: e.target.value,
                                    })
                                }
                            />
                            <Paragraph style={{marginBottom: '0px'}}>
                                Pincode
                            </Paragraph>
                            <Input
                                type="text"
                                name="city"
                                className="form-control"
                                value={locationArrBus?.pincode}
                                onChange={e =>
                                    setLocationBus({
                                        ...locationArrBus,
                                        pincode: e.target.value,
                                    })
                                }
                            />
                            <Row className="rowCenter">
                                {locationArrBus?.latitude ? (
                                    <Col span={12}>
                                        <span className="latlogSet">
                                            latitude
                                        </span>
                                        {locationArrBus?.latitude}
                                    </Col>
                                ) : (
                                    ''
                                )}
                                {locationArrBus?.longitude ? (
                                    <Col span={12}>
                                        <span className="latlogSet">
                                            longitude
                                        </span>
                                        {locationArrBus?.longitude}
                                    </Col>
                                ) : (
                                    ''
                                )}
                            </Row>
                        </Form.Item>

                        <Form.Item
                            style={{paddingTop: 10}}
                            label="About your company"
                            name="about_company"
                            initialValue={about_company}
                            labelAlign="left"
                            labelCol={{xs: 5}}>
                            <TextArea
                                autoSize={{minRows: 2, maxRows: 6}}
                                defaultValue={about_company}
                            />
                        </Form.Item>
                    </div>
                )}
                {/* --------------------------------------------------------------- Add bussiness end --------------------------------------------------------------------*/}
                <Form.Item>
                    <Layout
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            backgroundColor: 'transparent',
                        }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            style={{
                                width: '40%',
                                padding: '10px 10px',
                                height: 'max-content',
                                fontWeight: 'bold',
                            }}>
                            Submit
                        </Button>
                        <Button
                            type="default"
                            danger
                            htmlType="button"
                            //loading={authMutate.isLoading}
                            style={{
                                width: '40%',
                                padding: '10px 10px',
                                height: 'max-content',
                                fontWeight: 'bold',
                            }}>
                            <Link to={`/user/${params.id}`}>Cancel</Link>
                        </Button>
                    </Layout>
                </Form.Item>
            </Styled.CreateForm>
        </Styled.Root>
    )
}

export default EditUserView
