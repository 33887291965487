import React, {useState} from 'react'
import {Table, Spin, Button, Row} from 'antd'
import {useQuery} from 'react-query'

function DataTable({
    forceData,
    queryFunction,
    queryKey,
    columns,
    rowSelection,
    filters,
}) {
    const [pageNo, setPageNo] = useState(0)
    const {data, isLoading, isSuccess, isRefetching} = useQuery(
        [queryKey, pageNo, filters],
        queryFunction,
        {staleTime: 1800000, keepPreviousData: true, refetchInterval: 1800000},
    )

    if (isLoading) {
        return (
            <Row align='center' justify='center'>
                <Spin style={{marginTop: 100}} />
            </Row>
        )
    }

    const rows = (forceData && forceData.rows) || data.data.rows
    const count = (forceData && forceData.count) || data.data.count
    
    return (
        <Table
        className='main__table'
            rowSelection={rowSelection}
            columns={columns}
            dataSource={rows.map(item => ({...item, key: item.id}))}
            size='small'
            loading={isLoading || isRefetching}
            pagination={{
                pageSize: 50,
                current: pageNo + 1,
                total: +count,
                hideOnSinglePage: true,
                style: {padding: '0 20px'},
                showTotal: total => <Button>Total: {total}</Button>,
                disabled: isLoading || !isSuccess,
                onChange: page => {
                    setPageNo(page - 1)
                },
            }}
                    mobileBreakPoint={768}

            scroll={{y: '70vh', x: '70vw' }}
        />
    )
}

export default DataTable
