import * as API from '_axios'
import {ADMIN_URLS} from 'constants/url'

export const getAllOtp = async ({queryKey}) => {
    const [, pageNo, filters] = queryKey

    const result = await API.post(
        `${ADMIN_URLS.getOtp}?pageNo=${pageNo || 0}`,
        {
            telephone: filters.telephone || null,
        },
    )
    return result
}

export const getOtpById = async ({queryKey}) => {
    const [, id] = queryKey
    const result = await API.get(`${ADMIN_URLS.getOtp}/${id}`)
    return result
}
