import React from 'react'
import moment from 'moment'
import {Col, Row, List, Tag, Typography} from 'antd'
import {Spin} from 'antd'
import {useMutation, useQuery} from 'react-query'

import Item from './Item'
import * as flagsQueries from '../../../queries/flags.queries'
import * as Styled from './counterview.styled'
import {Link} from 'react-router-dom'

const {Title} = Typography

function FlagView({params}) {
    const flagsQuery = useQuery(['id', params.id], flagsQueries.getFlagsById)

    const flags = flagsQuery.data?.data.data
    if (flagsQuery.isLoading) {
        return (
            <Styled.Root>
                <Spin />
            </Styled.Root>
        )
    }

    return (
        <>
            <div className="order">
                <Col className="order_col">
                    <List
                        size="small"
                        bordered
                        style={{
                            background: 'rgba(0,0,0,0.03)',
                            paddingTop: '1.05rem',
                        }}
                        header={<Title level={5}>Basic Info</Title>}>
                        <Item
                            body={
                                <Link to={`/flags/${flags.id}`}>
                                    {flags.id}
                                </Link>
                            }
                            title="Id "
                        />
                        <Item
                            body={
                                <Link to={`/booking/${flags.order_id}`}>
                                    {flags.order_id}
                                </Link>
                            }
                            title="Order Id"
                        />
                        <Item
                            body={
                        
                                    <Link to={`/user/${flags.order_id}`}>
                                        {flags.title}
                                    </Link>
                               
                            }
                            title="Order Title"
                        />
                        <Item
                            body={
                                <Link to={`/user/${flags.user_id}`}>
                                    {flags.user_id}
                                </Link>
                            }
                            title="User Id"
                        />
                     
                    </List>
                </Col>
                <Col className="order_col">
                    <List
                        size="small"
                        bordered
                        style={{background: 'rgba(0,0,0,0.03)'}}>
                           <Item
                            body={
                        
                                    <Link to={`/user/${flags.user_id}`}>
                                        {flags.user_name}
                                    </Link>
                               
                            }
                            title="User Title"
                        />
                        <Item
                            body={flags.description || 'N/A'}
                            title="Description"
                        />

                        <Item
                            body={
                                moment(flags.created_at).format(
                                    'YYYY-DD-MM (hh:mm A)',
                                ) || 'N/A'
                            }
                            title="Created On"
                        />

                        <Item
                            body={
                                moment(flags.updated_at).format(
                                    'YYYY-DD-MM (hh:mm A)',
                                ) || 'N/A'
                            }
                            title="Updated On"
                        />
                    </List>
                </Col>
            </div>
        </>
    )
}

export default FlagView
