import * as API from '_axios'
import {ADMIN_URLS} from 'constants/url'

export const get_OOA_unsubscribed_users = async (pageNo) => {

    const result = await API.get(
        `${ADMIN_URLS.getUnsubscribedUsers}?pageNo=${pageNo}`
    )
    return result
}

export const get_CSV_OOA_unsubscribed_users = async (pageNo) => {

    const result = await API.get(
        `${ADMIN_URLS.getUnsubscribedUsers}/CSV`
    )
    return result
}