import React, {useState, useEffect} from 'react'
import {Typography, Button, Space, Input, Row} from 'antd'
import {Link} from 'react-router-dom'
import {PlusCircleFilled} from '@ant-design/icons'

import {useMutation, useQuery} from 'react-query'
import moment from 'moment'
import * as Queries from 'queries/report.queries'
import {QUERIES} from 'constants/index'
import DataTable from 'components/DataTable'

import * as Styled from './reports-styled'
import fileDownload from 'js-file-download'
import axios from 'axios'
import { ReloadOutlined } from '@ant-design/icons'

const {Title} = Typography

function Reports() {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: val => <Link to={`/reports/${val}`}>{val}</Link>,
            sorter: (a, b) => a.id - b.id,
            width: 110,
        },
        {
            title: 'Order ID',
            dataIndex: 'order_id',
            key: 'order_id',
            render: val => val || 'N/A',
        },
        {
            title: 'Reporter Name',

            key: 'reporter_name',
            render: val => (
                <Link to={`/user/${val.reporter_id}`}>{val.reporter_name}</Link>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Created On ',
            dataIndex: 'created_at',
            key: 'created_at',
            render: val => moment(val).format('YYYY-DD-MM (hh:mm A)') || 'N/A',
        },
        {
            title: 'Updated On ',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: val => moment(val).format('YYYY-DD-MM (hh:mm A)') || 'N/A',
        },
    ]

    const [enabled, setEnabled] = useState(false)
    const [filters, setFilters] = useState({
        reporterName: '',
    })
    const filterQuery = useQuery(
        [QUERIES.GET_ALL_REPORT, 0, filters],
        Queries.getAllReports,
        {
            staleTime: 180000,
            filters: enabled,
            enabled,
        },
    )

    /*
     * function to update the whole filtering data in the user page
     * {param} key: any string value representing the property of filters variable which is to be updated
     * {param} value: the value of that particular property of the filter
     */
    const onFiltersChange = (key, value) => {
        setFilters(values => {
            console.log(value)
            return {
                ...values,
                [key]: value,
            }
        })
    }
    const getAndSaveUserData = () => {
        filterQuery.refetch({
            queryKey: [QUERIES.GET_ALL_REPORT, 0, filters],
        })
    }
    // a function which will be used to clear all the filters to search user
    const onResetFilters = () => {
        setFilters({
            reporterName: '',
        })
    }

    useEffect(() => {
        if (filters.reporterName) setEnabled(true)
        else setEnabled(false)
    }, [filters])

    // function to export csv
    const [csv, setCsv] = useState(false)

    function downloadCsv(e) {
        e.preventDefault()
        setCsv(true)
        const token = localStorage.getItem('token')
        const csvDate = moment(new Date()).format('DD_MM_YYYY')

        axios
            .get(`${process.env.REACT_APP_BASE_URL}/admin/report/csv`, {
                headers: {
                    Authorization: token,
                },
            })
            .then(res => {
                fileDownload(res.data, `Reports_${csvDate}.csv`)
                setCsv(false)
            })
            .catch(err => {
                console.log(err)
                setCsv(false)
            })
    }

    function reloadApi(){
        filterQuery.refetch({
            queryKey:[QUERIES.GET_ALL_REPORT, 0, filters],
        })
    }

    return (
        <>
        <div style={{ textAlign: 'right', marginRight: '30px', marginBottom: '5px' }}> 
            <Button icon={<ReloadOutlined />} style={{textAlign:'right'}}   onClick={reloadApi} type="primary">Reload</Button>
        </div>
        <div>
            <Styled.FilterCollapse bordered ghost>
                <Styled.FilterCollapsePanel
                    header={<Title level={5}>Filters (click to toggle)</Title>}>
                    <Space direction="vertical">
                        {/* email filtering inputs */}
                        <Row align="middle" gutter={10}>
                            <Space direction="horizontal">
                                <Input
                                    allowClear
                                    value={filters.reporterName}
                                    placeholder="Reporter Name "
                                    onChange={e =>
                                        onFiltersChange(
                                            'reporterName',
                                            e.target.value,
                                        )
                                    }
                                />

                                <Button
                                    disabled={!Object.keys(filters).length > 0}
                                    onClick={getAndSaveUserData}
                                    type="primary">
                                    Search
                                </Button>
                                <Button
                                    disabled={!Object.keys(filters).length > 0}
                                    onClick={onResetFilters}
                                    type="ghost"
                                    danger>
                                    Reset Filters
                                </Button>
                            </Space>
                        </Row>
                    </Space>
                </Styled.FilterCollapsePanel>
            </Styled.FilterCollapse>

            <DataTable
                forceData={
                    false
                    // enabled &&
                    // filterQuery.isSuccess &&
                    // filterQuery.data.data &&
                    // filterQuery.data.data.count >= 0
                }
                columns={columns}
                filters={filters}
                queryFunction={Queries.getAllReports}
                queryKey={QUERIES.GET_ALL_REPORT}
            />

            <Button
                style={{margin: '2rem', minHeight: '45px', display: 'flex'}}
                onClick={e => downloadCsv(e)}
                loading={csv}
                type="light">
                <img
                    style={{width: '35px', height: '35px', marginRight: '5px'}}
                    src="/csb.png"
                    alt=""
                />
                Download CSV
            </Button>
        </div>
      </>
    )
}

export default Reports
