import * as API from '_axios'
import {ADMIN_URLS} from 'constants/url'

export const getAllCostAdjustments = async ({queryKey}) => {
    const [, pageNo,filters] = queryKey
   
    const result = await API.post(
        `${ADMIN_URLS.getCostAdjustment}?pageNo=${pageNo || 0}`,
        {
            order_id: filters.orderId || null,
            comments: filters.comment || null,
           
            
        }
        
    )
    
    return result
    
}



export const getCostAdjustmentById = async ({queryKey}) => {
    const [, id] = queryKey
    const result = await API.get(`${ADMIN_URLS.getCostAdjustmentById}/${id}`)
    return result
}