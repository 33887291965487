import {List, Space} from 'antd'
import React from 'react'
import styled from 'styled-components'

export const ListTag = styled.span`
    font-weight: bold;
    font-size: 12px;
    width: 100%;
    display: block;
`
function Item({title, body}) {
    return (
        <List.Item>
            <Space direction="vertical" size="small">
                <ListTag>{title}</ListTag>
                {body}
            </Space>
        </List.Item>
    )
}
export default Item
