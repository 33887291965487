import React from 'react'
import moment from 'moment'
import { Col, Row, List, Tag, Typography } from 'antd'
import { Spin } from 'antd'
import { useQuery } from 'react-query'

import Item from './Item'
import * as addressQueries from '../../../queries/address.queries'
import * as Styled from './counterview.styled'
import { Link } from 'react-router-dom'

const { Title } = Typography

function AddressView({ params }) {
    const addressQuery = useQuery(['id', params.id], addressQueries.getAddressById)

    const address = addressQuery.data?.data.data

    console.log(addressQuery.data?.data.data)
    if (addressQuery.isLoading) {
        return (
            <Styled.Root>
                <Spin />
            </Styled.Root>
        )
    }

    return (
        <>
            <div className="order">
                <Col className="order_col">
                    <List
                        size="small"
                        bordered
                        style={{
                            background: 'rgba(0,0,0,0.03)',
                            paddingTop: '1.05rem',
                        }}
                        header={<Title level={5}>Basic Info</Title>}>
                        <Item
                            body={
                                <Link to={`/address/${address.id}`}>
                                    {address.id}
                                </Link>
                            }
                            title="Id "
                        />
                        <Item
                            body={
                                address.addressable_type === "User" || address.addressable_type === "Business" ? (
                                    <Link
                                        to={`/user/${address.addressable_id}`}>
                                        {address.addressable_id}
                                    </Link>
                                ) : (
                                    <Link
                                        to={`/booking/${address.addressable_id}`}>
                                        {address.addressable_id}
                                    </Link>
                                )
                            }
                            title="Addressable Id"
                        />
                        <Item
                            body={
                                address.addressable_type === "User" || address.addressable_type === "Business" ? (
                                    <Link
                                        to={`/user/${address.addressable_id}`}>
                                        {address.addressable_type}
                                    </Link>
                                ) : (
                                    <Link
                                        to={`/booking/${address.addressable_id}`}>
                                        {address.addressable_type}
                                    </Link>
                                )
                            }
                            title="Addressable Type"
                        />

                        <Item body={address.line1 || 'N/A'} title="Line 1" />
                        <Item body={address.line2 || 'N/A'} title="Line 2" />

                        <Item body={address.city || 'N/A'} title="City" />
                    </List>
                </Col>
                <Col className="order_col">
                    <List
                        size="small"
                        bordered
                        style={{ background: 'rgba(0,0,0,0.03)' }}>
                        <Item body={address.state || 'N/A'} title="State" />

                        <Item
                            body={address.zip_code || 'N/A'}
                            title="Zip Code"
                        />

                        <Item
                            body={address.address_type || 'N/A'}
                            title="Address Type"
                        />
                        <Item
                            body={address.latitude || 'N/A'}
                            title="Address Latitude"
                        />
                        <Item
                            body={address.longitude || 'N/A'}
                            title="Address Longitude"
                        />

                        <Item
                            body={
                                moment(address.created_at).format(
                                    'YYYY-DD-MM (hh:mm A)',
                                ) || 'N/A'
                            }
                            title="Created On"
                        />

                        <Item
                            body={
                                moment(address.updated_at).format(
                                    'YYYY-DD-MM (hh:mm A)',
                                ) || 'N/A'
                            }
                            title="Updated On"
                        />
                    </List>
                </Col>
            </div>
        </>
    )
}

export default AddressView
