import * as API from '_axios'
import {ADMIN_URLS} from 'constants/url'

export const getAllReports = async ({queryKey}) => {
    const [, pageNo, filters] = queryKey
    const result = await API.post(
        `${ADMIN_URLS.getReports}?pageNo=${pageNo || 0}`,
        {
            reporter_name: filters.reporterName || null,
        },
    )
    return result
}

export const getReportById = async ({queryKey}) => {
    const [, id] = queryKey
    const result = await API.get(`${ADMIN_URLS.getReports}/${id}`)
    return result
}
