import React, {useState, useEffect} from 'react'
import {Typography, Button, Space, Input, Row, Tag} from 'antd'
import {Link} from 'react-router-dom'
import {PlusCircleFilled} from '@ant-design/icons'
import moment from 'moment'
import {useQuery} from 'react-query'

import * as Queries from 'queries/otp.queries'
import {QUERIES} from 'constants/index'
import DataTable from 'components/DataTable'

import * as Styled from './otp-styled'
import axios from 'axios'
import fileDownload from 'js-file-download'
import {ReloadOutlined} from '@ant-design/icons'

const {Title} = Typography

function Otp() {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'userId',
            render: val => <Link to={`/otp/${val}`}>{val}</Link>,
            sorter: (a, b) => a.id - b.id,
            width: 110,
        },
        {
            title: 'Telephone',
            dataIndex: 'telephone',
            key: 'telephone',
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Used',
            dataIndex: 'used',
            key: 'used',
            render: val => {
                return (
                    <Tag color={val ? (val === true ? 'cyan' : 'green') : null}>
                        {val ? (val === true ? 'USED' : 'UNUSED') : null}
                    </Tag>
                )
            },
        },
        {
            title: 'Expired On',
            dataIndex: 'expired_at',
            key: 'expired_at',
            render: val => moment(val).format('YYYY-DD-MM (hh:mm A)') || 'N/A',
            responsive: ['md'],
        },

        {
            title: 'Created On ',
            dataIndex: 'created_at',
            key: 'created_at',
            render: val => moment(val).format('YYYY-DD-MM (hh:mm A)') || 'N/A',
            responsive: ['md'],
        },
        {
            title: 'Updated On ',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: val => moment(val).format('YYYY-DD-MM (hh:mm A)') || 'N/A',
            responsive: ['md'],
        },
    ]

    const [enabled, setEnabled] = useState(false)
    const [filters, setFilters] = useState({
        telephone: '',
    })

    const filterQuery = useQuery(
        [QUERIES.GET_ALL_OTP, 0, filters],
        Queries.getAllOtp,
        {
            staleTime: 180000,
            filters: enabled,
            enabled,
        },
    )

    /*
     * function to update the whole filtering data in the user page
     * {param} key: any string value representing the property of filters variable which is to be updated
     * {param} value: the value of that particular property of the filter
     */
    const onFiltersChange = (key, value) => {
        setFilters(values => {
            console.log(value)
            return {
                ...values,
                [key]: value,
            }
        })
    }

    const getAndSaveUserData = () => {
        filterQuery.refetch({
            queryKey: [QUERIES.GET_ALL_OTP, 0, filters],
        })
    }
    
    // a function which will be used to clear all the filters to search user
    const onResetFilters = () => {
        setFilters({
            telephone: '',
        })
    }

    useEffect(() => {
        if (filters.email || filters.fullname || filters.telephone)
            setEnabled(true)
        else setEnabled(false)
    }, [filters])

    // function to export csv
    const [csv, setCsv] = useState(false)

    function downloadCsv(e) {
        e.preventDefault()
        setCsv(true)
        const token = localStorage.getItem('token')
        const csvDate = moment(new Date()).format('DD_MM_YYYY')

        axios
            .get(`${process.env.REACT_APP_BASE_URL}/admin/otp/csv`, {
                headers: {
                    Authorization: token,
                },
            })
            .then(res => {
                fileDownload(res.data, `OTPs_${csvDate}.csv`)
                setCsv(false)
            })
            .catch(err => {
                console.log(err)
                setCsv(false)
            })
    }

    const reloadApi = () => {
        filterQuery.refetch({
            queryKey: [QUERIES.GET_ALL_OTP, 0, filters],
        })
    }

    return (
        <>
            <div
                style={{
                    textAlign: 'right',
                    marginRight: '30px',
                    marginBottom: '5px',
                }}>
                <Button
                    icon={<ReloadOutlined />}
                    style={{textAlign: 'right'}}
                    onClick={reloadApi}
                    type="primary">
                    Reload
                </Button>
            </div>
            <div>
                <Styled.FilterCollapse bordered ghost>
                    <Styled.FilterCollapsePanel
                        header={
                            <Title level={5}>Filters (click to toggle)</Title>
                        }>
                        <Space direction="vertical">
                            {/* email filtering inputs */}
                            <Row align="middle" gutter={10}>
                                <Space direction="horizontal">
                                    <Input
                                        allowClear
                                        value={filters.telephone}
                                        placeholder="Telephone Number"
                                        onChange={e =>
                                            onFiltersChange(
                                                'telephone',
                                                e.target.value,
                                            )
                                        }
                                    />

                                    <Button
                                        disabled={
                                            !Object.keys(filters).length > 0
                                        }
                                        onClick={getAndSaveUserData}
                                        type="primary">
                                        Search
                                    </Button>
                                    <Button
                                        disabled={
                                            !Object.keys(filters).length > 0
                                        }
                                        onClick={onResetFilters}
                                        type="ghost"
                                        danger>
                                        Reset Filters
                                    </Button>
                                </Space>
                            </Row>
                        </Space>
                    </Styled.FilterCollapsePanel>
                </Styled.FilterCollapse>

                <DataTable
                    forceData={
                        false
                        // enabled &&
                        // filterQuery.isSuccess &&
                        // filterQuery.data.data &&
                        // filterQuery.data.data.count >= 0
                    }
                    columns={columns}
                    filters={filters}
                    queryFunction={Queries.getAllOtp}
                    queryKey={QUERIES.GET_ALL_OTP}
                />

                <Button
                    style={{margin: '2rem', minHeight: '45px', display: 'flex'}}
                    onClick={e => downloadCsv(e)}
                    loading={csv}
                    type="light">
                    <img
                        style={{
                            width: '35px',
                            height: '35px',
                            marginRight: '5px',
                        }}
                        src="/csb.png"
                        alt=""
                    />
                    Download CSV
                </Button>
            </div>
        </>
    )
}

export default Otp
