import * as API from '_axios'
import { ADMIN_URLS } from 'constants/url'

export const getAllOrderDeclines =async({queryKey})=>{
    const [, pageNo,filters]=queryKey
    const result=await API.post(
        `${ADMIN_URLS.getOrderDeclines}?pageNo=${pageNo || 0}`,
        {
            order_id: filters.orderId || null, 
            gopher_name: filters.gopherName || null, 
           
        }
    )
    return result
    
}

//get order declines by id
export const getOrderDeclinesById = async ({queryKey}) =>{
    const [, id]=   queryKey
    const result = await API.get(`${ADMIN_URLS.getOrderDeclinesById}/${id}`)
    console.log(result);
    return result
}

