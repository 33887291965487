import React from 'react'
import moment from 'moment'
import { Col, Row, List, Tag, Typography } from 'antd'
import { Spin } from 'antd'
import { useQuery } from 'react-query'

import Item from './Item'
import * as costAdjustmentQueries from '../../../queries/costadjustment.queries'
import * as Styled from './counterview.styled'
import { Link } from 'react-router-dom'

const { Title } = Typography

function CostAdjustmentView({ params }) {
    const costAdjustmentQuery = useQuery(['id', params.id], costAdjustmentQueries.getCostAdjustmentById)

    const cost = costAdjustmentQuery.data?.data.data

    //console.log(bidsQuery.data?.data.data)
    if (costAdjustmentQuery.isLoading) {
        return (
            <Styled.Root>
                <Spin />
            </Styled.Root>
        )
    }

    return (
        <>  
            <div className="order">
                <Col sm={4} md={6} xl={8}>
                    <List
                        size="small"
                        bordered
                        style={{
                            background: 'rgba(0,0,0,0.03)',
                            paddingTop: '1.05rem',
                        }}
                        header={<Title level={5}>Basic Info</Title>}>
                        
                        <Item body={cost.id} title="ID#" />
                        <Item body={<Link to={`/booking/${cost.order_id}`} >{cost.order_id}</Link>}  title="Order ID " />
                        <Item body={cost.comments || "N/A" } title="Comment" />
                        <Item
                            body={
                                cost.status === 'Declined' ? (
                                    <Tag color="red">{cost.status}</Tag>
                                ) : cost.status === 'Accepted' ?(
                                    <Tag color="green">{cost.status}</Tag>
                                ) : (<Tag color="blue">{cost.status}</Tag>)
                            }
                            title="Status"
                        />
                       
                       
                    </List>
                </Col>
                <Col sm={16} md={12}  xl={8}>
                        <List
                        size="small"
                        bordered
                        style={{background: 'rgba(0,0,0,0.03)'}}>
                        <Item body={`$ ${parseFloat(cost.original_offer / 100).toFixed(2)}`} title="Original Offer ($)" />
                        <Item body={`$ ${parseFloat(cost.original_cost / 100).toFixed(2)}`} title="Original Cost ($)" />
                        <Item body={`$ ${parseFloat(cost.new_offer / 100).toFixed(2)}`} title="New Offer ($)" />
                        <Item body={`$ ${parseFloat(cost.new_cost / 100).toFixed(2)}`} title="New Cost ($)" />
                        <Item
                            body={moment(cost.createdon).format(
                                'YYYY-DD-MM',
                            )}
                            title="Created On"
                        />
                       
                       
                    </List>
                </Col>
                <Col sm={4} md={6} xl={8}>
                        <List
                        size="small"
                        bordered
                        style={{background: 'rgba(0,0,0,0.03)'}}>
                        <Item body={cost.original_noof_hour ? cost.original_noof_hour : 0} title="Original No Of Hour" />
                        <Item body={cost.new_noof_hour ? cost.new_noof_hour : 0} title="New No Of Hour" />
                        <Item body={`$ ${parseFloat(cost.original_hourly_wage / 100).toFixed(2)}`} title="Original Hourly Wage" />
                        <Item body={`$ ${parseFloat(cost.new_hourly_wage / 100).toFixed(2)}`} title="New Hourly Wage" />
                        <Item body={cost.original_gopher_needed ? cost.original_gopher_needed : 0} title="Original Gopher Needed" />
                        <Item body={cost.new_gopher_needed ? cost.new_gopher_needed : 0} title="New Gopher Needed" />
                       
                    </List>
                </Col>
            </div>
        </>
    )
}

export default CostAdjustmentView
