import React from 'react'
import moment from 'moment'
import { Col, Row, List, Tag, Typography } from 'antd'
import { Spin } from 'antd'
import { useQuery } from 'react-query'

import Item from './Item'
import * as bidsQueries from '../../../queries/bids.queries'
import * as Styled from './counterview.styled'
import { Link } from 'react-router-dom'

const { Title } = Typography

function BidsView({ params }) {
    const bidsQuery = useQuery(['id', params.id], bidsQueries.getBidsById)

    const bids = bidsQuery.data?.data.data

    //console.log(bidsQuery.data?.data.data)
    if (bidsQuery.isLoading) {
        return (
            <Styled.Root>
                <Spin />
            </Styled.Root>
        )
    }

    return (
        <>  
            <div className="order">
                <Col className="order_col">
                    <List
                        size="small"
                        bordered
                        style={{
                            background: 'rgba(0,0,0,0.03)',
                            paddingTop: '1.05rem',
                        }}
                        header={<Title level={5}>Basic Info</Title>}>
                        
                        <Item body={bids.id} title="ID#" />
                        <Item body={<Link to={`/booking/${bids.order_id}`} >{bids.order_id}</Link>}  title="Order ID " />
                        <Item body={bids.title} title="Title" />
                        <Item body={<Link to={`/user/${bids.gopher_id}`} >{bids.gopher}</Link>}  title="Gopher Name" />
                        <Item body={<Link to={`/user/${bids.requestor_id}`} >{bids.requestor}</Link>} title="Requestor Name" />
                        <Item
                            body={
                                bids.status === 'Declined' ? (
                                    <Tag color="red">{bids.status}</Tag>
                                ) : bids.status === 'Accepted' ?(
                                    <Tag color="green">{bids.status}</Tag>
                                ) : (<Tag color="blue">{bids.status}</Tag>)
                            }
                            title="Status"
                        />
                    </List>
                </Col>
                <Col className="order_col">
                        <List
                        size="small"
                        bordered
                        style={{background: 'rgba(0,0,0,0.03)'}}>
                        <Item body={`$ ${parseFloat(bids.offer / 100).toFixed(2)}`} title="Offer ($)" />
                        <Item body={`$ ${parseFloat(bids.cost_of_goods / 100).toFixed(2)}`} title="Cost of Goods ($)" />
                        <Item body={bids.comment || "N/A" } title="Comment" />

                        <Item
                            body={moment(bids.createdon).format(
                                'YYYY-DD-MM',
                            )}
                            title="Created On"
                        />
                    </List>
                </Col>
                
            </div>
        </>
    )
}

export default BidsView
