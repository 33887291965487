import React from 'react'
import moment from 'moment'
import {
    Col,
    Row,
    List,
    Tag,

    Typography,
  
} from 'antd'
import {Spin} from 'antd'
import {useMutation, useQuery} from 'react-query'

import Item from './Item'
import * as counterQueries from '../../../queries/counter.queries'
import * as Styled from './counterview.styled'
import {Link} from 'react-router-dom'

const {Title} = Typography


function CounterView({params}) {

    const counterQuery = useQuery(['id', params.id], counterQueries.getCounterById)


    
    const counter = counterQuery.data?.data.data
    if (counterQuery.isLoading) {
        return (
            <Styled.Root>
                <Spin />
            </Styled.Root>
        )
    }


    return (
        <>
    
    <div className="order">
                            <Col  sm={4} md={6} xl={8}>
                    <List
                        size="small"
                        bordered
                        style={{background: 'rgba(0,0,0,0.03)'}}
                        header={<Title level={5}>Basic Info</Title>}>
                
                        <Item body={counter.id || "N/A"} title="ID#" />
                        <Item body={counter.title || "N/A"} title="Title" />
                       
                        <Item body={<Link to={`/booking/${counter.order_id}`} >{counter.order_id}</Link>}  title="Order ID " />
                        
                        <Item body={<Link to={`/user/${counter.gopher_id}`} >{counter.gopher}</Link>}  title="Gopher" />
                        <Item body={
                            
                            <Link to={`/user/${counter.requestor_id}`} >{counter.requestor}</Link>} title="Requestor" />
                        <Item
                            body={
                                counter.status !== "Declined" ? (
                                    <Tag color="green">{counter.status}</Tag>
                                ) : (
                                    <Tag color="red">{counter.status}</Tag>
                                )
                            }
                            title="Status"
                        />
                       
                           </List>
                        </Col>
                        <Col sm={16} md={12}  xl={8}>
                        <List
                        size="small"
                        bordered
                        style={{background: 'rgba(0,0,0,0.03)'}}>
                        <Item body={`$ ${parseFloat(counter.order_offer / 100).toFixed(2)}`} title="Order Offer" />
                        <Item body={`$ ${parseFloat(counter.order_cog / 100).toFixed(2)}`} title="Order COG" />
                        <Item body={`$ ${parseFloat(counter.counter_offer / 100).toFixed(2)}`} title="Counter Offer" />
                        <Item body={`$ ${parseFloat(counter.counter_cog / 100).toFixed(2)}`} title="Counter COG" />
                        <Item body={counter.comment || "N/A"} title="Comment" />

                        <Item
                            body={moment(counter.created_at).format(
                                'YYYY-DD-MM',
                            )}
                            title="Created On"
                        />
                         
                    </List>
                </Col>
               
                <Col sm={4} md={6} xl={8}>
                        <List
                        size="small"
                        bordered
                        style={{background: 'rgba(0,0,0,0.03)'}}>
                       
                        <Item body={`$ ${parseFloat(counter.order_hourly_wage / 100).toFixed(2)}`} title="Order Hourly wage" />
                        <Item body={`$ ${parseFloat(counter.counter_hourly_wage / 100).toFixed(2)}`} title="Counter Hourly wage" />
                        <Item body={counter.order_gopher_needed ? counter.order_gopher_needed : 0} title="Order Gopher Needed" />
                        <Item body={counter.counter_gopher_needed ? counter.counter_gopher_needed : 0} title="Counter Gopher Needed" />
                        <Item body={counter.order_noof_hours ? counter.order_noof_hours : 0} title="Order No Of Hours" />
                        <Item body={counter.counter_noof_hours ? counter.counter_noof_hours : 0} title="Counter No Of Hours" />
                    </List>
                </Col>

</div>
             
        </>
    )
}

export default CounterView
